import React from 'react'
import { RiCloseLine, RiShoppingCartLine } from 'react-icons/ri'
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { getDashOrders } from "../../../redux/features/orders/ordersSlice"


const MobileNavbar = ({ dashMobileMenu, setDashMobileMenu }) => {

    const closeMobileNavbar = () => {
        setDashMobileMenu(!dashMobileMenu);
    }


    const ordersList = useSelector((state) => state.ordersList)
    const { ordersData } = ordersList;


  return (
    <div className={`${dashMobileMenu ? 'mobileNavbarActive' : ''} mobileNavbar__container`}>

        <RiCloseLine className='icon-main icon-position' onClick={() => closeMobileNavbar()} />

        <Link to="/" ><div className="mobileNavbar-img"></div></Link>

        <div className='mobileNavbar-header'>
            <Link to="/orders" className='link-style-icon'><RiShoppingCartLine className="icon-main icon-dash-green " /></Link>

            { ordersData.greska ? 
                <span className='order__product-count'><h3>0</h3></span> : 
                <span className='order__product-count'><h3>{ordersData.length}</h3></span> }

            <span className='link-style ml-2'><Link to="/orders" className='link-style' onClick={() => closeMobileNavbar()}>Porudzbine</Link></span>
        </div>

    </div>
  )
}

export default MobileNavbar