import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { IoLogoWhatsapp, IoCloseOutline } from 'react-icons/io5'

import whatsupLogo from '../../assets/img/logos/whatsapp.png';
// import click from '../../assets/sound/click.mp3';


const Whatsapp = ({ chat, setChat }) => {


  const closeMessage = () => {
    setChat({message: false, whatsapp: true});
  }


  return (
    <>
      <div className={`${chat.message ? 'messageActice' : ''} message`}>
          <IoCloseOutline className='icon-main messageClose' onClick={closeMessage} />
          <p>Poštovani,</p>
          <p>Kako možemo da vam pomognemo?</p>
      </div>
      <article className={`${chat.whatsapp ? 'whatsupActive' : ''} whatsapp__container`} >
          <a href="https://wa.me/381638327451" target="_blank" > <img className='whatspp-ico' src={whatsupLogo} alt="whatsup logo" /> </a>
      </article>
    </>
  )
}

export default Whatsapp