const resetPass = ( api, sendData, setEmail, notifyError, notifySuccess, notifyInfo, setIsLoading, setCards ) => {

    api({
        method: 'post',
        url: 'reset.php?fun=reset',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {

                setEmail('');
                notifySuccess(response.data.uspesno);
                setCards({ newPass: true})
                setIsLoading(false)

            } else if (response.data.greska) {
                notifyError(response.data.greska);
                setIsLoading(false)

            } else if (response.data.info) {
                notifyInfo(response.data.info);
                setIsLoading(false)
            }
        })

}

export default resetPass