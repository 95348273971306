import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './fonts/static/Nunito-Light.ttf'
import './fonts/static/Nunito-Regular.ttf'
import './fonts/static/Nunito-Bold.ttf'

// Redux
import { useDispatch } from 'react-redux';
import { countTotal } from './redux/features/cart/cartSlice';

// Components
import Message from './components/Message/Message';
import Totop from './components/ToTop/Totop';
import { ToastContainer } from 'react-toastify';

// Pages
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import Login from './pages/Login/Login';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Products from './pages/Products/Products';
import Product from './pages/Product/Product';

// Legal pages
import Terms from './pages/LegalPages/Terms/Terms';
import Privacy from './pages/LegalPages/Privacy/Privacy';
import Cookies from './pages/LegalPages/Cookies/Cookies';
import Refund from './pages/LegalPages/Refund/Refund';
import Payment from './pages/Payment/Payment';
import Delivery from './pages/LegalPages/Delivery/Delivery';


// User Dashboard pages
import Dashboard from './pages/Dashboard/Dashboard';
import Orders from './pages/Dashboard/Orders';
import UserInfo from './pages/Dashboard/UserInfo';
import Whatsup from './components/Whatsapp/Whatsapp';
import Whatsapp from './components/Whatsapp/Whatsapp';
import CookesComp from './components/CookesCop/CookiesComp';
import ThankYou from './pages/ThankYou/ThankYou';


function App() {

  const [location, setLocation] = useState('/');
  const [message, setMessage] = useState('');

  const [chat, setChat] = useState({
    whatsapp: false,
    message: false
  });


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countTotal());
  }, [dispatch]);



  return (
    <Router>

        <Totop />
        <ToastContainer autoClose={2500} />
        <Whatsapp chat={chat} setChat={setChat}/>
        <CookesComp chat={chat} setChat={setChat}  />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/About' element={<About location={location} setLocation={setLocation} />} />
          <Route path='/Login' element={<Login location={location} setLocation={setLocation} message={message} setMessage={setMessage} />} />
          <Route path='/Payment' element={<Payment location={location} setLocation={setLocation} />} />

          <Route path='/Terms' element={<Terms location={location} setLocation={setLocation} />} />
          <Route path='/Privacy' element={<Privacy location={location} setLocation={setLocation} />} />
          <Route path='/Cookies' element={<Cookies location={location} setLocation={setLocation} />} />
          <Route path='/Refund' element={<Refund location={location} setLocation={setLocation} />} />
          <Route path='/Delivery' element={<Delivery location={location} setLocation={setLocation} />} />
          <Route path='/Thankyou' element={<ThankYou location={location} setLocation={setLocation} />} />

          <Route path='/Products' element={<Products location={location} setLocation={setLocation} />} >
            <Route path=':singleid' element={<Products />} />
          </Route>

          <Route path='/Product' element={<Product location={location} setLocation={setLocation} />} >
            <Route path=':singleProduct' element={<Product />} />
          </Route>


          <Route path='/Dashboard' element={<Dashboard location={location} setLocation={setLocation} />} />
          <Route path='/orders' element={<Orders location={location} setLocation={setLocation} />} />
          <Route path='/userinfo' element={<UserInfo location={location} setLocation={setLocation} />} />



          <Route path='*' element={<ErrorPage error />} />
        </Routes>

    </Router>
  );
}

export default App;
