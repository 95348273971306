import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion'

import Footer from '../../../components/Footer/Footer'
import Navbar from '../../../components/Navbar/Navbar';
import Header from '../../../components/Header/Header';
import Devider from '../../../components/Devider/Devider';

import { Helmet } from 'react-helmet-async';


const Privacy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Politika privatnosti | SmiliesOnline Shop</title>

                <meta name="description" content="SmiliesOnline Shop stranica na kojoj možete da se upoznate sa našom politikom privatnosti." ></meta>

                <link  rel='canonical' href='/Privacy'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />

            <section className="legal__container">
                <div className="legal__container-content container-xxl">
                    <h2>Politika privatnosti</h2>
                    <p>PR Smilies, Beograd se obavezuje da štiti privatnost kupaca i korisnika sajta i garantuje da će podatke koje kupac odnosno korisnik sajta ostavi koristiti, čuvatii obrađivati u skladu sa svrhom i pravnim osnovom, zbog kojih su prikupljeni.</p>
                    <p>PR smilies, Beograd će preduzeti odgovarajuće tehničke i organizacione mere, u cilju obezbeđivanja da se obrada podataka o ličnosti vrši u skladu sa pozitivnopravnim propisima i kako bi se sprečio gubitak, zloupotreba ili izmena podataka o ličnosti kupaca.</p>
                    <p>Podatke koje ste nam poverili nećemo dostaviti neovlašćenim trećim licima i sa njima ćemo postupati u skladu sa važećim Zakonom o zaštiti podataka o ličnosti (“Sl. Glasnik RS” br. 87/2018). Uvid u prikupljene podatke imaju isključivo ovlašćena loca u PR Smilies, Beograd, takođe obrađivači i primaoci sa kojima je regulisan odnos. Svi zaposleni su svesni značaja čuvanja tajnosti podataka i odgovorni su za poštovanje ove Politike privatnosti.</p>
                    <p>Poštovani korisnici, molimo vas da pre korišćenja naših usluga, pažljivo pročitate sledeće uslove. Svaka poseta našoj stranici, kao i kupovina, znači da ste ove uslove pročitali i da se slažete sa njima u celosti. Ukoliko su oni za vas neprihvatljivi, molimo vas da ne koristite ovu veb-stranicu</p>
                    <p>Ova Politika privatnosti sadrži informacije o vrsti ličnih podataka koji se obrađuju, o pravnom osnovu i svrsi obrade ličnih podataka, pravima lica na koja se podaci odnose, o rukovaocu, obrađivačima i primaocima ličnih podataka, vremenskom periodu čuvanja podataka o ličnosti i izmenama i dopunama ove Politike privatnosti.</p>

                    <h3>Podaci o rukovaocu</h3>
                    <p>Naziv: Smilja Grujesković PR Smilies, proizvodnja ostale odeće</p>
                    <p>Sedište: Maršala Birjuzova 45/23</p>
                    <p>Matični broj: ...</p>
                    <p>PIB: 110976770</p>
                    <p>Mail: info@smiliesonline.com</p>
                    <p>Telefon: 00381-63-8-327-451</p>
                    <p></p>

                    <h3>Podaci o ličnosti koji se obrađuju</h3>
                    <p>Ime i prezime</p>
                    <p>Adresa stanovanja</p>
                    <p>Broj telefona</p>
                    <p>email adresa</p>
                    <p>IP adresa</p>

                    <p>Pravni osnov za obradu podataka o ličnosti može biti:</p>
                    <ul>
                        <li> <p>Pristanak, dat od strane posetioca veb-stranice SmiliesOnline, kojom dozvoljavaju prikupljanje za jasno naznačenu svrhu. Pristanak se može povući u bilo kom trenutku.</p></li>
                        <li> <p>Slanjem mejla odgovarajućeg sadržaja na info@smiliesonline.com ili slanjem dopisa na adresu koja je prethodno naznačena;</p></li>
                        <li> <p>Zaključenje i/ili izvršenje ugovora;</p> </li>
                        <li> <p>Poštovanje pravnih obaveza rukovaoca;</p> </li>
                        <li> <p>Zaštita životno važnih interesa lica na koje se podaci odnose ili drugog fizičkog lica;</p> </li>
                        <li> <p>Obavljanje poslova u javnom interesu ili izvršenje zakonom propisanih ovlašćenja rukovaoca;</p> </li>
                        <li> <p>Legitimni interes rukovaoca - za marketinške i analitičke svrhe. Naš legitimni interes zasnovan je na Vašim željama, kako bismo što bolje prilagodili našu ponudu i na kraju ponudili proizvode i usluge koji bolje odgovaraju Vašim potrebama i željama;</p> </li>
                    </ul>
                    <p>Pravni osnov određen je u skladu sa čl. 12 važećeg Zakona o zaštiti podataka o ličnosti</p>

                    <p>Svrha obrade:</p>
                    <ul>
                        <li> <p>Odgovaranja na upit ili zahtev korisnika i pružanja najbolje moguće usluge;</p> </li>
                        <li> <p>Obrađivanja porudžbine (npr. obaveštavanje kupca o statusu narudžbine, vremenu dostave, odgovaranje na eventualne zahteve u vezi narudžbine i sl.)</p> </li>
                        <li> <p>Omogućavanje kupcima podnošenje reklamacija i povrata u onlajn prodavnici;</p> </li>
                        <li> <p>Omogućavanje kupcima različitih načina plaćanja;</p> </li>
                        <li> <p>Omogućavanje kupcima povraćaj sredstava plaćanja;</p> </li>
                        <li> <p>Omogućavanje prikazivanja potrošačima sadržaja na veb-stranici na najučinkovitiji način;</p> </li>
                        <li> <p>Filtriranje sadržaja koji je prilagođen Vašim interesovanjima, to jest kako bismo Vam ponudili svoje proizvode za koje je utvrđeno da ih preferiraju
                            korisnici istog pola i istog uzrasta, u cilju olakšavanja pretrage;</p> </li>
                        <li> <p>Obaveštavanje o promenama odredaba, uslova i pravila i/ili drugih administrativnih podataka;</p> </li>
                        <li> <p>Pohranjivanje bilo kojeg popisa liste želja, koje korisnici veb-stranice mogu kreirati;</p> </li>
                        <li> <p>Omogućavanje učestvovanja korisnika u nagradnim igrama i sličnim promocijama;</p> </li>
                        <li> <p>Prilagođavanje sadržaja korisnika našim veb-stranicama, u cilju pružanja personalizovane ponude;</p> </li>
                        <li> <p>Otkrivanje i/ili sprečavanje zloupotrebe ili prevare.</p> </li>
                    </ul>

                    <p>Vaše podatke možemo obrađivati i u marketinške svrhe na temelju vaše posebne saglasnosti, i to sa svrhom:</p>
                    <ul>
                        <li> <p>Izveštavanja o posebnim pogodnostima, koje su često vremenski ograničene;</p> </li>
                        <li> <p>Izveštavanja o sezonskim sniženjima, akcijskim prodajama i ostalim prodajnim pogodnostima;</p> </li>
                        <li> <p>Izveštavanja o posebnim akcijama rezervisanim za naše članove;</p> </li>
                        <li> <p>Obaveštavanja o novim kolekcijama;</p> </li>
                        <li> <p>Dostavljanja korisnih informacija vezanih za SmiliesOnline internet prodavnicu i druge maloprodajne objekte;</p> </li>
                        <li> <p>Kontaktiranja u vezi oglašavanja i istraživanja tržišta (slanje upitnika, anketa i sl.);</p> </li>
                        <li> <p>Marketinških analiza, u cilju poboljšanju prodajne ponude.</p> </li>
                    </ul>


                    <h3>1.1 Registracija i otvaranje profila</h3>
                    <p>SmiliesOnline internet prodavnicu možete koristiti u svojstvu “Registrovanog korisnika” ili “Gosta”</p>
                    <p>Za registraciju i otvaranje korisničkog profila potrebni su nam sledeći lični podaci: Ime i prezime, emejl adresa, broj telefona, adresa i mesto prebivališta, datum rođenja i IP adresa.</p>
                    <p>Vaš SmiliesOnline profil dostupan je samo nakon registracije na veb-stranici sa tačnim registracionim podacima, tj. mejl adresom i lozinkom - zato Vas molimo da te podatke čuvate i da koristite sigurne (složene) lozinke. Preporučujemo da prilikom odabira kombinacije znakova za lozinku kombinujete velika, mala slova i brojeve, te da svakako upotrebite lozinku od barem šest znakova. Preporučujemo i da lozinku promenite makar jednom godišnje. SmiliesOnline Beograd nema pristup lozinci, tako da je korisnik isključivo odgovoran za zaštitu svoje lozinke.</p>
                    <p>Svaki korisnik odgovara za tačnost i istinitost svih informacija koje je dao.</p>
                    <p>Korisnik garantuje da će svojim profilom upravljati lično, odnosno preko lica koje ovlasti.</p>
                    <p>PR Smilies Beograd čuva podatke do opoziva ili odjave profila u skladu sa zakonskom regulativom Republike Srbije. Korisnik se može odjaviti u bilo kom trenutku, slanjem pisanog obaveštenja odgovarajuće sadržine u elektronskoj formi na mejl adresu info@smiliesonline.com (mejl mora biti poslat sa elektronske adrese sa koje se korisnik prijavio kao registrovani korisnik) ili slanjem dopisa na adresu sedišta rukovaoca. Nakon što deaktivirate svoju registraciju (odjavite svoj profil na veb-stranici, odjavite se sa newslettera i sl.), SmiliesOnline internet prodavnica će čuvati Vaše podatke u roku od 6 meseci od dana deregistracije, u cilju rešavanja eventualnih reklamacija iz prethodnog perioda.</p>
                    <p>Gore navedeni podaci (osim lozinke) i istorija porudžbina na vašem profilu, dostupni su zaposlenima u PR Smilies Beograd i obrađivačima sa kojima rukovalac ima regulisan odnos, kada je to neophodno za ispunjenje svrhe obrade. Ako vas zanima ko sve može imati pristup vašim ličnim podacima, molimo vas pročitajte rubriku „Obrađivači i primaoci ličnih podataka.“</p>


                    <h3>1.2 Poručivanje proizvoda od strane "Gosta"</h3>
                    <p>Kupovinu u našoj onlajn prodavnici može ostvariti i neregistrovani korisnik - “gost”.</p>
                    <p>U cilju uspešnog obrađivanja narudžbine od strane “gosta” potrebni su nam sledeći lični podaci: Ime i prezime, adresa i mesto isporuke, mejl adresa
                        i kontakt telefon. Ukoliko ne pružite tražene podatke, PR Smilies verovatno neće moći da obradi Vašu porudžbinu i dostavi vam naručenu robu. Svaki korisnik odgovora za tačnost podataka koje je uneo.</p>
                    <p>U skladu sa načelom minimizacije podataka, od Vas samo tražimo osnovne podatke, koji su nam neophodni za sprovođenje onlajn prodaje Odnosno podatke koje će nam omogućiti da Vas, u skladu sa propozicijama PR Smilies Beograd i dobrim poslovnim običajima, informišemo o svemu što može doprineti poboljšanju naše usluge, sa jedne, te povećanju Vašeg zadovoljstva našom uslugom, sa druge strane.</p>


                    <h3>1.3 Dostava proizvoda naručenih u online prodavnici</h3>
                    <p>Lične podatke kontakt osobe za isporuku, tj. ime i prezime, adresa isporuke, mejl adresa i broj telefona, dobijene potvrdom narudžbine, delimo sa našim partnerima za logistiku (kurirska služba), kako bismo pripremili proizvode iz vaše narudžbine i dostavili ih na adresu prema vašem zahtevu. Poslovni partneri ovlašćeni su te podatke koristiti i kontaktirati Vas isključivo u svrhu isporuke pojedinačnih pošiljki.</p>


                    <h3>1.4 Postavljanje upita ili zahteva</h3>
                    <p>Korisnik našeg veb-sajta može nam postaviti specifična pitanja posredstvom kontaktnih obrazaca, koji su dostupni na SmiliesOnline internet prodavnicu ili alternativno, putem mejl adrese. Podaci koje korisnik dostavi (IP adresa i/ili mejl adresa) prilikom postavljanja željenih upita biće korišćeni za rešavanje korisnikovih pitanja.</p>
                    <p>Navedena mejl adresa nije lična, već poslovna e-adresa PR Smiies Beograd. Dakle, ista nije vezana za jedno fizičko lice, već podatke dostavljene ovim putem mogu obrađivati različite osobe, zaposlene u PR Smilies, a sve u cilju brzog i efikasnog rešavanja korisnikovog zahteva.</p>
                    <p>Upite ili zahteve možete postaviti preko chat forme kompanije Tawk.to ili putem mejla info@smiliesonline.com .</p>
                    <p>Slanjem e-poruke posredstvom chat obrasca ili na mejl adrese navedene na veb-sajtu www.smiliesonline.com korisnik se slaže sa opštim uslovima korišćenja naše onlajn prodavnice.</p>
                    <p>Takođe, ukoliko nam ne pružite navedene neophodne podatke prilikom postavljanja upita ili zahteva, PR SMilies Beograd verovatno neće moći da postupi na zatraženi način.</p>

                    <h3>1.5 Plaćanje u internet prodavnici</h3>
                    <p>Podaci sa kreditnih i debitnih kartica, koje unosite pri plaćanju u SmiliesOnline internet prodavnici ne primaju se i ne obrađuju u našim sistemima. Obrada se odvija u sistemima i tehničkoj i zakonskoj odgovornosti naših partnera za onlajn naplatu (Banca Intesa), a mi dobijamo podatke o statusu transakcije.</p>
                    <p>PR SMilies nikada ne dolazi u kontakt sa celovitim podacima o Vašoj platnoj kartici.</p>
                    <p>Formular za upis platnih podataka osiguran je SSL transportnom šifrom najveće pouzdanosti.</p>
                    <p>Sigurnost podataka prilikom kupovine garantuju naši partneri za onlajn naplatu pa se tako kompletni proces naplate obavlja na stranicama Banca Intesa. Ni jednog trenutka podaci o platnoj kartici nisu dostupni našem sistemu.</p>
                    <p>Povraćaj sredstava se vrši isključivo preko panela Bance Intesa, što znači da će banka na zahtev prodavca obaviti povraćaj na račun korisnika kartice.</p>


                    <h3>1.6 Nesletter i upotreba podataka za marketing</h3>
                    <p>Putem newsletter-a PR Smilies Beograd obaveštava korisnike koji pošalju svoj pristanak o: novostima u ponudi u našoj onlajn prodavnici i maloprodajnim objektima, tekućim marketinškim aktivnostima, programu lojalnosti i drugim programima vernosti, nagradnim igrama, posebnim događajima koje PR Smilies Beograd organizuje, kao i o svim drugim aktuelnim događanjima vezanih za PR Smilies Beograd. </p>
                    <p>Kako biste se prijavili za primanje newsletter-a dovoljno je da pošaljete adresu e-pošte, na koju želite primati newsletter. U svakom trenutku možete se odjaviti sa newsletter-a u skladu sa uputstvima koje ćete dobiti e-poštom nakon prijave, kao i u svakom newsletter-u.</p>

                    <p>Prijavom na newsletter izjavljujete da ste saglasni da Vršimo procenu Vaše aktivnosti na našem sajtu, u cilju prilagođavanja SmiliesOnline internet prodavnice Vašim zahtevima i interesovanjima, odnosno da dobijanja personalizovane kompilacije vesti, ponuda i drugih informacija. Vaši podaci se čuvaju i koriste za istraživanje tržišta i u promotivne svrhe. Ukoliko je potrebno, kontaktiraćemo Vas isključivo putem mejl adrese, koju ste nam pružali kao podatak o ličnosti. Vaše podatke ćemo koristiti i za analizu i poboljšanje efikasnosti PR Smilies internet prodavnice.</p>
                    <p>Ako prilikom prijave na newsletter odaberete opciju da želite da primate poruke koje su više prilagođene vašim interesovanjima, PR SMilies će navedene podatke obrađivati kako bi se filtrirao sadržaj koji je prilagođen Vašim interesovanjima, a sve u cilju olakšavanja pretraživanja naše ponude. PR Smilies će obrađivati navedene podatke za pomenute ponude samo ako ste za to dali svoj pristanak.</p>
                    <p>Nakon što deaktivirate svoju registraciju (odjavite svoj profil na veb-stranici, odjavite se sa newslettera i sl.), SmiliesONline internet prodavnica će čuvati Vaše podatke o ličnosti u roku od 6 meseci od dana deregistracije, u cilju rešavanja eventualnih reklamacija iz prethodnog perioda.</p>


                    <h3>1.7 Podaci prikupljeni kroz interakciju sa SmiliesOnline</h3>
                    <p>Različite tehnologije mogu se koristiti na SmiliesOnline internet prodavnici, kako bismo je poboljšali, učinili je jednostavnijom, prilagođenoj korisnicima, efikasnijom i sigurnom. Takve tehnologije mogu dovesti do automatskog prikupljanja ličnih podataka od strane nas ili trećih strana u naše ime. Primeri takvih tehnologija uključuju kolačiće, flash kolačiće i veb-analizu.</p>

                    <h4>1.7.1 Click-stream podaci</h4>
                    <p>Možemo prikupljati informacije o vašem računaru za svrhe sistemske administracije, kao i za prijavljivanje zbirnih informacija za potrebe unutrašnjih marketinških analiza. Ovo su podaci o postupcima i uzorcima pregledanja naših korisnika i mogu uključivati sledeće:</p>
                    <ul>
                        <li> <p>IP adresu posetioca</p> </li>
                        <li> <p>Datum i vreme posete</p> </li>
                        <li> <p>URL preporuke (veb-mesto sa koje je posetilac poslat)</p> </li>
                        <li> <p>Posećene stranice i “putovanje” korisnika na našoj veb-stranici</p> </li>
                        <li> <p>Informacije o korišćenom pregledaču (tip, verzija, operativni sistem i sl.).</p> </li>
                    </ul>

                    <h4>1.7.2 Kolačići (cookies)</h4>
                    <p>Kolačić je tekstualna datoteka koju veb-server postavlja na vaš hard disk. Kolačići se ne mogu koristiti za pokretanje programa ili isporuku zlonamernog softvera na Vaš računar.</p>
                    <p>Našu Politiku kolačića i detaljne informacije o njihovoj upotrebi možete pročitati  <Link to='Privacy'>ovde.</Link> .</p>

                    <h4>1.7.3 Re-targeting tehnologije</h4>
                    <p>PR Smilies internet prodavnica koristi re-targeting tehnologije. To nam omogućava direktno i lično oglašavanje posetiocima koji su već pokazali interesovanje za naše proizvode.</p>
                    <p>Re-targeting tehnologije analiziraju Vaše kolačiće i prikazuju oglase na osnovu vašeg prethodnog ponašanja pri surfovanju internetom. Za više informacija o kolačićima pogledajte našu politiku kolačića <Link to='Privacy'>ovde.</Link></p>


                    <h3>Kako možete ostvariti svoja prava, kontakt za izricanje potrebe izricanja pritužbi, rešavanje pritužbi  </h3>
                    <p>PR SMilies</p>
                    <p>Ul. Maršala Birjuzova 45/24</p>
                    <p>11000 Beograd</p>
                    <p>Republika Srbija</p>
                    <p>Email: info@smiliesonline.com</p>
                    <p>Da bismo vas zaštitili kao titulara ličnih podataka, potrebno je da se na odgovarajući način identifikujete kao podnosilac prijave, koji će za PR Smilies Beograd predstavljati jemstvo da lične podatke daje njihovom vlasniku.</p>

                    <h3>Primaoci podataka o ličnosti</h3>
                    <p>PR Smilies Beograd deli podatke o ličnosti potrošača sa sledećim poslovnim partnerima, u svrhe ispunjavanja funkcije PR Smilies internet prodavnice i pružanja Potrošačima kvalitetnog iskustva na PR Smilies internet prodavnici:</p>
                    <p>Tawk - kompanija koja pruža usluge ćaskanja na PR Smilies internet prodavnici, putem koje PR Smilies internet prodavnica može Potrošačima da brzo i adekvatno pruži tražene informacije, a u tom cilju navedena kompanija prima podatke o IP adresi i državi iz koje dolazi Potrošač;</p>
                    <p>Prilikom obrade podataka o ličnosti PR Smilies Beograd može vaše lične podatke podeliti sa svojim poslovnim partnerima ili povezanim licima, na teritoriji Republike Srbije ili u inostranstvu.</p>

                    {/*   <p>BANKA</p>*/}
                    {/*   <p>KURIRSKA SLUZBA</p>*/}


                    <h3>Vremenski rok u kom se vaši lični podaci čuvaju</h3>
                    <p>Podaci koje dostavite se čuvaju onoliko koliko je potrebno da bi se ispunila određena svrha zbog koje ste nam dostavili podatke ili da bi se ispoštovale zakonske odredbe.</p>
                    <p>Svi prikupljeni podaci u vezi registrovanog korisničkog profila čuvaju se do trenutka njegove deaktivacije. Nakon što deaktivirate svoju registraciju (odjavite svoj profil na veb-stranici, odjavite se sa newslettera i sl.), PR Smilies će u narednih šest meseci nakon deregistracije čuvati vaše lične podatke kako bi se u tom razdoblju rešile eventualne reklamacije iz prethodnog razdoblja.</p>
                    <p>Ako ste nam dali svoju izričitu saglasnost za upotrebu ličnih podataka u promotivne svrhe, mi ćemo koristiti vaše podatke za takvu namenu, sve dok ne opozovete svoju saglasnost. Možete opozvati saglasnost koju ste dali u svakom trenutku tako da opoziv proizvodi dejstvo u budućnosti.</p>
                    <p>Ako ste nam dali svoju izričitu saglasnost za upotrebu ličnih podataka u promotivne svrhe, mi ćemo koristiti vaše podatke za takvu namenu, sve dok ne opozovete svoju saglasnost. Možete opozvati saglasnost koju ste dali u svakom trenutku tako da opoziv proizvodi dejstvo u budućnosti.</p>
                    <p>Ako to zahtevaju pozitivnopravni propisi, PR Smilies navedene podatke može čuvati i duže, u skladu sa propisanim rokom čuvanja podataka.</p>
                    <p>Po isteku perioda čuvanja, lične podatke brišemo trajno ili ih činimo anonimnim, tako da se više ne mogu povezati sa određenim pojedincem.</p>

                    <h3>Maloletnici</h3>
                    <p>Maloletnici mlađi od 15 godina ne smeju da koriste PR Smilies veb-stranicu. PR Smilies Beograd ne prikuplja, niti zadržava lične podatke od lica mlađeg od 15 godina, te nijedan deo ove Internet stranice nije dizajniran na način da privuče bilo koga ko je mlađi od 15 godina. Ako roditelji, rođak ili zakonski zastupnik takvog lica otkrije da je poslalo lične podatke bez njegove saglasnosti, obratite se na našu adresu ili putem mejla na info@smiliesonline.com .</p>

                    <h3>Izmene i dopune politike privatnosti</h3>
                    <p>Politika o privatnosti se može povremeno menjati kako bi se uskladila sa pozitivnopravnim propisima.</p>
                    <p>Sve promene koje možemo načiniti u Politici privatnosti biće objavljene na ovoj stranici i prema potrebi, možete biti obavešteni putem e-pošte.</p>

                    <h3>Stupanje na snagu politike privatnosti</h3>
                    <p>01.01.2023</p>
                </div>
            </section>

            <Footer />
        </motion.div>
    )
}

export default Privacy