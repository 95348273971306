import React from 'react'
import { IoCloseOutline } from 'react-icons/io5'

import pictoImg from '../../assets/img/picktogram/woman.png';
import { useState } from 'react';

const HowToMeasure = ({ howToMeasure, setHowToMeasure }) => {

    const [pictoDesc, setPictoDesc] = useState('');


    const closePicto = () => {
        setHowToMeasure(!howToMeasure);
    }


    const renderDesc = () => {

        switch (pictoDesc) {
            case 'O1':
                return(
                    <p>Meriti u krug preko naj šireg dela grudi.</p>
                )
                break;
            case 'O2':
                return(
                    <p>Meriti u krug preko naj užeg dela struka.</p>
                )
                break;
            case 'O3':
                return(
                    <p>Meriti u krug preko naj šireg dela bokova.</p>
                )
                break;
            case 'O4':
                return(
                    <p>Meriti na leđima, od jedne do druge spoljne ivice ramena.</p>
                )
                break;
            case 'O5':
                return(
                    <p>Meriti od ivice ramena do korena palca.</p>
                )
                break;
            case 'O6':
                return(
                    <p>Meriti po unutrasnjoj strani noge do poda.</p>
                )
                break;
        
            default:
                return(
                    <p></p>
                )
                break;
        }
    }



    return (
    
        <div className={`${howToMeasure ? 'activePicto' : ''} pictogram-container`}>
            <div className="pictogram-container-content">
             
                <span><IoCloseOutline className="closePicto icon-main" onClick={closePicto} /></span>
                <p>Klik na broj za opis.</p>
                
                <div className="picto-img">
        
                    <img src={pictoImg} alt="" />
                    <p className="krug O1" onClick={() => setPictoDesc('O1')}>1</p>
                    <p className="krug O2" onClick={() => setPictoDesc('O2')}>2</p>
                    <p className="krug O3" onClick={() => setPictoDesc('O3')}>3</p>
                    <p className="krug O4" onClick={() => setPictoDesc('O4')}>4</p>
                    <p className="krug O5" onClick={() => setPictoDesc('O5')}>5</p>
                    <p className="krug O6" onClick={() => setPictoDesc('O6')}>6</p>

                    <div className="odgovor" >{renderDesc()}</div>
                </div>
            </div>
        </div>
    )
}

export default HowToMeasure