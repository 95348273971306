import React, {useEffect, useState} from 'react'
import click from '../../assets/sound/click.mp3';


const CookiesComp = ({ chat, setChat }) => {

  const [cookeComp, setCookieComp] = useState(false);
  const smiliesCookie = sessionStorage.getItem("SmiliesOnlineCookie");

  useEffect(() =>{

    setTimeout(() => {
      // On first page load cookie message will popup. 
      // If user confirms, on second load no message will be displayed.
      if(!smiliesCookie){
  
        sessionStorage.setItem("SmiliesOnlineCookie", JSON.stringify({
          cookieConfirm:true,
        }));
        setCookieComp(true);
        
      }else{
        openChat()
      }
      
    }, '1000');
  }, [])


  const playIt = () =>{
    let sound = new Audio(click);
    sound.play()
  }


  const closeCookies = () => {
    setCookieComp(false)

    // On cookies confirm session will bee loaded and no more cocokies popup for 6h
    if(smiliesCookie){
      setTimeout(() => {
        sessionStorage.removeItem('SmiliesOnlineCookie')
      }, '21600000')
    }

    openChat()
  }


  const openChat = () => {
    setTimeout(() => {
      setChat({whatsapp: true, message: true});
    }, '1500');

    setTimeout(() => {
      playIt()
    }, '1100');
  }


  return (
    <div className={`${cookeComp ? '' : 'cookieClose'} cookiesComp__container`} >
        <p>Koristmo samo osnovne kolačiće kako bismo Vam omogućili bolje korisničko iskustvo.</p>
        <button className='btn' type='button' onClick={closeCookies}>U redu</button>
    </div>
  )
}

export default CookiesComp