import React, { useEffect } from 'react'
import Footer from '../../../components/Footer/Footer'
import { motion } from 'framer-motion/dist/framer-motion'

import Navbar from '../../../components/Navbar/Navbar';
import Header from '../../../components/Header/Header';
import Devider from '../../../components/Devider/Devider';

import { Helmet } from 'react-helmet-async';

const Cookies = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])



    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Politika kolačića | SmiliesOnline Shop</title>

                <meta name="description" content="SmiliesOnline Shop stranica na kojoj možete da se upoznate sa našom politikom kolačića." ></meta>

                <link  rel='canonical' href='/Cookies'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />

            <section className="legal__container">
                <div className="legal__container-content container-xxl">
                    <h2>Politika kolačića</h2>
                    <h3>1. Obaveštenje o Kolačićima</h3>
                    <p>Kako bi SmiliesOnline internet prodavnica radila ispravno, te kako bismo bili u stanju vršiti unapređenja stranice, u svrhu poboljšavanja iskustva pretraživanja Potrošača, ova stranica mora na Vaš računar spremiti malu količinu informacija (kolačići, Cookies).</p>
                    <p>Korišćenjem SmiliesOnline internet prodavnice pristajete na upotrebu kolačića koji su nužni, u cilju obezbeđivanja pojedinih tehničkih karakteristika i pružanja Potrošačima pozitivnog korisničkog iskustva. Za korišćenje kolačića, koji nisu nužni za funkcionisanje Internet stranice, ali poboljšavaju uslugu, zatražićemo saglasnost Potrošača.</p>
                    <p>Ako Potrošač ne da svoju saglasnost za korišćenje određenih kolačića - i dalje može pregledati stranicu, ali neke mogućnosti neće biti dostupne.</p>
                    <p>Korišćenjem SmiliesOnline internet prodavnice Potrošač pristajete na korišćenje kolačića u skladu sa ovom politikom kolačića.</p>
                    <p>PR Smilies Beograd - Stari grad može ažurirati ovu Politiku kolačića u bilo kom trenutku.</p>

                    <h3>2. Šta su kolačići</h3>
                    <p>Kolačići su tekstualne datoteke, koji sadrže male količine informacija koje se učitavaju na uređaj pri poseti određenog veb-sajta. Kolačići se potom šalju nazad do izvornih internet domena prilikom kasnijih poseta tom domenu. Ovo omogućava veb-sajtu da prikaže informacije prilagođene ličnim potrebama korisnika, jer pomoću kolačića veb-sajt pamti radnje i podešavanja tokom određenog razdoblja. Svaki put kada korisnik otvori neku internet stranicu, veb-server može pročitati vrednosti koje je ranije spremio u kolačićima.</p>
                    <p>Većina veb-sajtova sadrži elemente iz višestrukih internet domena, tako da kada posetite veb-sajt internet pretraživač može da primi kolačiće iz više izvora.</p>
                    <p>Kolačići su korisni zato što omogućavaju veb-sajtovima da prepoznaju uređaj određenog korisnika. Kolačići omogućavaju korisnicima da efikasno obavljaju navigaciju između stranica, zapamte prioritete i generalno unaprede korisničko iskustvo. Oni takođe mogu da se koriste u cilju prilagođavanja reklamiranja interesovanjima određenog korisnika, kroz praćenje pretraživanja i drugih veb-sajtova.</p>
                    <p>Kolačići su korisni zato što omogućavaju veb-sajtovima da prepoznaju uređaj određenog korisnika. Kolačići omogućavaju korisnicima da efikasno obavljaju navigaciju između stranica, zapamte prioritete i generalno unaprede korisničko iskustvo. Oni takođe mogu da se koriste u cilju prilagođavanja reklamiranja interesovanjima određenog korisnika, kroz praćenje pretraživanja i drugih veb-sajtova.</p>
                    <p>Kolačići nisu:</p>
                    <ul>
                        <li><p>Namenjeni špijuniranju korisnika i NE prate sve što korisnik radi;</p></li>
                        <li><p>Maliciozni kod, niti virus;</p></li>
                        <li><p>Vezani za neželjene poruke;</p></li>
                        <li><p>U stanju da prate Vaše komande na tastaturi (ne čuvaju Vaše šifre).</p></li>
                    </ul>
                    <p>Kolačići su jedino i isključivo namenjeni analitici i reklamiranju.</p>

                    <h3>2. Kolačići treće strane</h3>
                    <p>Postoji nekoliko eksternih servisa koji korisniku skladište limitirane kolačiće. Ovi kolačići nisu postavljeni od strane SmiliesOnline veb-stranice, ali neki služe za normalno funkcionisanje određenih mogućnosti, koji korisnicima olakšavaju pristup sadržaju. SmiliesOnline trenutno omogućuje merenje posećenosti.</p>
                    <p>Za merenje posećenosti SmiliesOnline koristi sledeće servise: Google Analytics i Facebook Pixel</p>
                    <p>Google politiku privatnosti u pogledu Google analitike možete pronaću na <a target='_blank' href="https://support.google.com/analytics/answer/6004245?hl=sr">Google Analitics</a>. </p>
                    <p>Facebook politiku privatnosti možete pročitati <a target='_blank' href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0"> Facebook </a>.</p>

                </div>
            </section>

            <Footer />
        </motion.div>
    )
}

export default Cookies