import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'
import { motion } from 'framer-motion/dist/framer-motion'

import Footer from '../../components/Footer/Footer';
import ProductDescription from '../../components/ProductDescription/ProductDescription';
import SimilarProducts from '../../components/SimilarProducts/SimilarProducts';
import SingleProduct from '../../components/SingleProduct/SingleProduct';
import ErrorPage from '../ErrorPage/ErrorPage';

import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import Devider from '../../components/Devider/Devider';

import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from "../../redux/features/products/productsSlice"
import Loader from '../../components/Loader/Loader';

import { Helmet } from 'react-helmet-async';

const slike = process.env.REACT_APP_BACKEND_PRODUCT_IMAGES;
const odrzavanje = process.env.REACT_APP_BACKEND_MAINTENANCE_IMAGES;


const Product = ({ location, setLocation }) => {

    let curentLocation = useLocation()

    const productsList = useSelector((state) => state.productsList)
    const { loading, data, message } = productsList;
    const dispatch = useDispatch();

    useEffect(() => {
        setLocation(curentLocation.pathname);
        dispatch(getProducts());
        
        window.scrollTo(0, 0)
    }, [dispatch]);

    let { singleProduct } = useParams();


    if (loading) {
        return <Loader />
    } else if (data.length == 0) {
        return <ErrorPage noproduct />
    } else {

        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
            >


                <Helmet >
                    <title>Proizvod | SmiliesOnline Shop</title>

                    <meta name="description" content="SmiliesOnline Shop stranica pojedinačnog proizvoda." ></meta>

                    <link  rel='canonical' href='/Product'/>             
                </Helmet>


                <Navbar />
                <Header />
                <Devider />
                <div >
                    {
                        data.map((proizvod, idx) => {

                            if (singleProduct == proizvod.proizvod_id) {
                                return (
                                    <div idx={idx} className='container' key={idx}>
                                        <SingleProduct proizvod={proizvod} slike={slike} />
                                        <ProductDescription proizvod={proizvod} odrzavanje={odrzavanje} />

                                        <SimilarProducts proizvod={proizvod} />
                                    </div>
                                )
                            }
                        })
                    }
                </div>

                <Footer />
            </motion.div>
        )
    }
}

export default Product