const makeOrder = ( api, sendData, notifyError, notifySuccess, notifyInfo, navigate, setIsLoading ) => {

    api({
        method: 'post',
        url: 'itemsOrdering.php?fun=add',
        data: sendData,
    })
        .then((response) => {
            if (response.data.uspesno) {
                setIsLoading(false)
                notifySuccess(response.data.uspesno);
                navigate('/Thankyou')
            } else if (response.data.greska) {
                notifyError(response.data.greska);
                setIsLoading(false)
            } else if (response.data.info) {
                notifyInfo(response.data.info);
                setIsLoading(false)
            }
        })

}

export default makeOrder