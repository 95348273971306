import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { motion } from 'framer-motion/dist/framer-motion'

import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import Devider from '../../components/Devider/Devider';
import Footer from '../../components/Footer/Footer';

import AboutComp from '../../components/AboutComp/AboutComp';
import Gallery from '../../components/Gallery/Gallery';
import { Helmet } from 'react-helmet-async';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                {/* <title>About</title>
                <meta  name='description' content='SmiliesOnline Shop je internet prodavnica Smilies brenda. Pored svoje primarne delatnosti šivenja odeće po meri, Smilies brend sada ima i online prodavnicu svoje unikatne odevne kolekcije' /> */}

                <title>O nama - SmiliesOnline prodavnica custom odeće i unikatne odevne kolekcije iz Beograda | SmiliesOnline</title>

                <meta name="description" content="Naša priča o Smilies - online prodavnici custom odeće. Saznajte više o našoj misiji, viziji i prednostima koje nudimo kupcima. Brza isporuka, jedinstveni dizajn i visok kvalitet materijala."/>
                
                <link  rel='canonical' href='/About'/>             
            </Helmet>
            

            <Navbar />
            <Header />
            <Devider />

            <AboutComp />
            <Gallery />
            <Footer />
        </motion.div>
    )
}

export default About