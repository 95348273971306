const signUp = (api, sendData, setCards, notifyError, 
    notifySuccess, notifyInfo, setFirstName, setLastName, 
    setEmail, setPassword, setRepeatPassword, setTerms, setIsLoading, e ) => {


    api({
        method: 'post',
        url: 'signup.php',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {
                setCards({ login: true, signup: false })
                notifySuccess(response.data.uspesno);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPassword('');
                setRepeatPassword('');
                setTerms(false);
                e.target.terms.checked = false;
                setIsLoading(false)

            } else if (response.data.greska) {
                notifyError(response.data.greska);
                setIsLoading(false)
            } else if (response.data.info) {
                notifyInfo(response.data.info);
                setIsLoading(false)
            }
        })
}

export default signUp