import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import DetailsWindow from '../../components/Dashboard/DetailsWindow/DetailsWindow'
import Header from '../../components/Dashboard/Header/Header'
import MobileNavbar from '../../components/Dashboard/MobileNavbar/MobileNavbar'
import Navbar from '../../components/Dashboard/Navbar/Navbar'
import OrdersList from '../../components/Dashboard/OrdersList/OrdersList'
import Modal from '../../components/Modal/Modal'
import ErrorPage from '../ErrorPage/ErrorPage'


const Orders = () => {

    document.title = 'User Orders';

    // Session
    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");

    const [asideMenu, setAsideMenu] = useState(false);

    const [detailsWindow, setDetailsWindow] = useState(false);
    const [orderOption, setOrderOption] = useState('');
    const [modal, setModal] = useState(false)
    const [modalId, setModalId] = useState('');
    const [dashMobileMenu, setDashMobileMenu] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        const asideMenuStorage = localStorage.getItem('sidebar');
        if (asideMenuStorage === 'closed') {
            setAsideMenu(true);
        }   

    }, [asideMenu]);



    if (smiliesSession) {

        return (
            <div className='dashboard-container'>

                <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                <MobileNavbar dashMobileMenu={dashMobileMenu} setDashMobileMenu={setDashMobileMenu} />

                <div className="dashboard__main">
                    <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} dashMobileMenu={dashMobileMenu} setDashMobileMenu={setDashMobileMenu} />

                    {/* <div className="main__section category__layout-grid"> */}
                    <div className="main__section ">
                        <OrdersList

                            detailsWindow={detailsWindow}
                            setDetailsWindow={setDetailsWindow}
                            orderOption={orderOption}
                            setOrderOption={setOrderOption}
                            modal={modal}
                            setModal={setModal}
                            modalId={modalId}
                            setModalId={setModalId}
                        />

                        <DetailsWindow

                            detailsWindow={detailsWindow}
                            setDetailsWindow={setDetailsWindow}
                            orderOption={orderOption}
                            setOrderOption={setOrderOption}
                            modal={modal}
                            setModal={setModal}
                            modalId={modalId}
                            setModalId={setModalId}
                        />

                        <Modal modal={modal} setModal={setModal} modalId={modalId} setDetailsWindow={setDetailsWindow} detailsWindow={detailsWindow} />

                    </div>
                </div>
            </div>
        )
    }else {
        return (
            <ErrorPage nologin />
        )
    }
}

export default Orders