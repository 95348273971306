import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion'

import LoginCard from '../../components/LoginCard/LoginCard';
import SigninCard from '../../components/LoginCard/SigninCard';
import NewPassCard from '../../components/LoginCard/NewPassCard';

import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import Devider from '../../components/Devider/Devider';
import RessetPassCard from '../../components/LoginCard/RessetPassCard';

import { Helmet } from 'react-helmet-async';


const LoginSignin = ({ setLocation }) => {

    let curentLocation = useLocation();
    useEffect(() => {
        setLocation(curentLocation.pathname);
    }, []);


    const [cards, setCards ] = useState({
        login: true,
        signup: false,
        resetPass: false,
        newPass: false,
    })


    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Login | SmiliesOnline Shop</title>

                <meta name="description" content="SmiliesOnline Shop stranica za logovanje i prjavljivanje na svoj nalog gde možete pratiti svoje porudžbine." ></meta>

                <link  rel='canonical' href='/Login'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />

            <div className="container">
                <div className="login-container">
                    <LoginCard  cards={cards} setCards={setCards} />
                    <SigninCard  cards={cards} setCards={setCards} />
                    <RessetPassCard  cards={cards} setCards={setCards}/>
                    <NewPassCard  cards={cards} setCards={setCards}/>
                </div>
            </div>
        </motion.div>
    )
}

export default LoginSignin