import React, { useEffect, useState } from 'react'
import { RiDeleteBinLine, RiEyeLine } from 'react-icons/ri'
import 'react-toastify/dist/ReactToastify.css';

import { useSelector, useDispatch } from 'react-redux';
import { getDashOrders, editOrder } from "../../../redux/features/orders/ordersSlice"
import Loader from '../../Loader/Loader';

const slike = process.env.REACT_APP_BACKEND_PRODUCT_IMAGES;


const Orders = ({ detailsWindow, setDetailsWindow, orderOption, setOrderOption, modal, setModal, modalId, setModalId}) => {

    const ordersList = useSelector((state) => state.ordersList)
    const { ordersLoading, ordersData, ordersMessage } = ordersList;
    
 
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDashOrders());
    }, [dispatch]);


    // Order update despatc to Order redux EditOrder reducer
    const updateOrder = (order) => {
        setDetailsWindow(!detailsWindow);
        setModal(!modal);
        dispatch(editOrder({
            order
        }, [dispatch]))
    }



    // Render osrder status icon 
    const orderStatus = (status) => {
        switch (status) {
            case 'ceka-slanje':
                return (
                    <span className='order__status order__status-danger'></span>
                )
                break;
            case 'poslato':
                return (
                    <span className='order__status order__status-info'></span>
                )
                break;
            case 'isporuceno':
                return (
                    <span className='order__status order__status-success'></span>
                )
                break;
            default:
                return (
                    <span className='order__status order__status-default'></span>
                )
        }
    }


    const orderImages = (splitSlike) => {

        let arr = [];
        let maxVal = splitSlike.length / 4;
        let delta = Math.floor(splitSlike.length / maxVal);

        for (let i = 0; i < splitSlike.length; i = i + delta) {
            arr.push(splitSlike[i]);
        }

        return arr.map((item, idx) => {
            return (
                <img src={slike + item} alt="" />
            )
        })
    }


    return (
        <div className='orderList__container'>
            <h2>Moje porudžbine</h2>

                {
                    ordersLoading ?  <Loader />  : ordersData.greska ? <h3 className='color-danger mt-1 tc-danger-muted'>{ordersData.greska}</h3> :

                    ordersData.length == 0 ? <h3 className='color-danger mt-1 tc-danger-muted'>Nema porudzbina za prikaz.</h3> : 
        
                        ordersData.map((order, idx) => {

                            const splitSlike = order.slika_ime.split(',');

                            return(
                                <div className="order-single" key={idx}>

                                    <div className="order-single-item">
                                        <RiEyeLine className='icon-dash-success icon-small' 
                                            onClick={() => [ updateOrder(order), setOrderOption('viewOrder'), setModalId('orderDetails')]} />
                                        <p>{order.por_id}</p> 
                                        {orderStatus(order.por_status)}
                                        <p>{order.por_status}</p> 
                                    </div>

                                    <div className="pipe-devider">
                                        <p>|</p>
                                    </div>
                                    
                                    <div className="order-single-item">
                                        <p>{order.por_email}</p>
                                        <p>{order.por_broj_tel}</p>
                                    </div>

                                    {/* <div className="order-single-item">
                                        <p>|</p>
                                    </div> */}
                                                                        
                                    {/* <div className="order-single-item">
                                       <p>{order.por_placanje}</p>
                                        <p>{order.por_preuzimanje}</p> 
                                    </div> */}

                                    <div className="pipe-devider">
                                        <p>|</p>
                                    </div>
                                    
                                    <div className="order-single-item">
                                        <p>{order.por_ulica}</p>
                                        <p>{order.por_broj_ulaz}</p>
                                        <p>{order.por_grad}</p>
                                    </div>

                                    {/* <div className="order-single-item">
                                        <p>|</p>
                                    </div> */}

                                    <div className='order-single-item'>
                                        {/* <p> {orderPrice(price)} ,00 din </p> */}
                                    </div>

                                    <div className="order-single-img">
                                        {orderImages(splitSlike)}
                                    </div>

                                </div>
                            )
                        })
                }
        </div>
    )
}

export default Orders