import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion'

import HomeSection from '../../components/HomeSection/HomeSection';
import SplideCarusel from '../../components/SplideCarusel/SplideCarusel';

import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import Devider from '../../components/Devider/Devider';
import Footer from '../../components/Footer/Footer';

import { Helmet } from 'react-helmet-async';


const Home = () => {

    document.title = 'Home';

    // Session
    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");
    const navigate = useNavigate();


    useEffect(() => {
    // Sets session duration. 
    // After that time session will expire and user will be loged out
    setTimeout(() => {
        if (smiliesSession) {
            sessionStorage.removeItem('SmiliesOnlineLog')
            navigate('/')
        }
      }, 900000);

      window.scrollTo(0, 0)

    }, [smiliesSession]);


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Početna | SmiliesOnline Shop</title>

                <meta name="description" content="SmiliesOnline Shop je internet prodavnica brenda Smilies koji ima više od 25 godina iskustva u šivenju odeće po meri a od skora i online prodaju unikatne odevne kolekcije" ></meta>
                {/* <meta name="description" content="SmiliesOnline Shop je internet prodavnica Smilies brenda koji nezavisno postiji i posluje više od 25 godina. Pored svoje primarne delatnosti šivenja odeće po meri, Smilies brend sada ima i online prodavnicu svoje unikatne odevne kolekcije." ></meta> */}
 
                <meta name="robots" content="index, follow"></meta>

                <link  rel='canonical' href='/'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />

            <SplideCarusel />
            <HomeSection />
            <Footer />

        </motion.div>
    )
}

export default Home