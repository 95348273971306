import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store'

import './sass/main.css';
import './index.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>

    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>

  </React.Fragment>
);

