const orderPrice = (price) => {

    let arr = [];
    let maxVal = price.length / 4;
    let delta = Math.floor(price.length / maxVal);

    for (let i = 0; i < price.length; i = i + delta) {
        arr.push(price[i]);
    }

    let sum = 0;

    arr.forEach(element => {
        sum += Number(element)
    });

    return sum;
}

export default orderPrice