import ShowcaseImg2 from '../src/assets/img/home-sections/SmiliesBuisiness-01.jpg'
import ShowcaseImg3 from '../src/assets/img/home-sections/SmiliesEveryday-01.jpg'
import ShowcaseImg4 from '../src/assets/img/home-sections/SmilesWeding-01.jpg'


export const showcases = [
    {
        id: 2,
        title: 'Smilies Business',
        text: 'Kolekcija namenjena je za sve poslovne prilike. Inspirisana je jakim i uspešnim ženama. Spoj je poslovnog odevanja i prefinjene elegancije. Ovi komadi odeće su sačinjeni od pažljivo biranih najmodernijih i najfinijih  materijala.',
        image: ShowcaseImg2,
        image_description: 'Smilies bussiness collection',
    },
    {
        id: 3,
        title: 'Smilies Every Day',
        text: 'U ovoj kolekcji možete naći ležerne komade odeće, koji idu u korak sa trendovima. Modeli su puni boja, mašte i mogu se nositi svakodnevno, bilo da je to šetnja sa prijateljima, popodnevni ili večernji izlazak.',
        image: ShowcaseImg3,
        image_description: 'Smilies every day collection',
    },
    {
        id: 4,
        title: 'Smilies Wedding',
        text: 'Venčanja su jedan poseban čin i u našoj kolekciji možete naći nešto posebno za tu priliku. Svila, saten, čipka, ukrasni kamenčići su neki od materijala koje koristimo za izradu odevnih predmeta iz ove kolekcije.',
        image: ShowcaseImg4,
        image_description: 'Smilies wedding collection',
    }
]
