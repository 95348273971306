import React, { useEffect, useState } from 'react'
import './SplideCarusel.css';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';

import { splideImages } from '../../splideData'


const SplideCarusel = () => {

    // const [splideImages, setSplideImages ] = useState([]);

    // useEffect(() => {
    //     setSplideImages(splideImages)
    // }, [])

    // console.log(splideImages)

    return (
        <div>
            <Splide
                options={{
                    type: 'loop',
                    autoplay: true,
                    interval: 4000,
                    speed: 1100,
                    height: 'auto',
                    width: '100%',
                    arrows: true,
                    pagination: false,
                    lazyLoad: true,
                    mouseDrag: true,
                    // easing: 'ease',
                    pauseOnHover: false,

                    breakpoints: {
                        3840: {
                            height: '90rem'
                        },

                        1920: {
                            // height: '88rem'
                            height: '68rem'
                        },

                        768: {
                            height: '44rem',
                        }, 
                    }
                }}

                hasTrack={false} aria-label="Smilies slider images">

                <SplideTrack>

                    {
                        splideImages.map((img, idx) => {
                            return(
                                <SplideSlide>
                                    <img key={idx} src={img.image} alt={img.desc} />
                                </SplideSlide>
                            )
                        })
                    }

                </SplideTrack>

                <div className="splide__progress">
                    <div className="splide__progress__bar" />
                </div>

            </Splide>
        </div>
    )
}

export default SplideCarusel