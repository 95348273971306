import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom';
import { IoPersonOutline,IoLogOutOutline } from 'react-icons/io5'

const OptionsMenu = ({ setOptionsMenu, optionsMenu }) => {

    // Session
    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");
    const sessionKill = () => {
        sessionStorage.removeItem("SmiliesOnlineLog");
        setOptionsMenu(false)
    }

    const closeOptionsMenu = () => {
        setOptionsMenu(false)
    }

    return (
        <div className= {`${optionsMenu ? 'optionsMenuVisible' : ''} optionsMenu__container`} >
            <ul>
                <li className='mobile-registration' onClick={closeOptionsMenu} ><Link to="/orders" data-en='Log Out' data-sr='Odjava'> <span><IoPersonOutline className='mr-1 icon-small' />Profil</span> </Link></li>
                <li className='mobile-registration' onClick={sessionKill}> <span><IoLogOutOutline className='mr-1 icon-small' />Odjava</span></li>
            </ul>
        </div>
    )
}

export default OptionsMenu