import React from 'react';
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import api from '../../api/api';

import newPass from '../../utilities/newPass';


// Styling
import { IoPersonOutline, IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5'
import 'react-toastify/dist/ReactToastify.css';
import Message from '../Message/Message';

const ResetCard = ({ cards, setCards }) => {


    const [showLoginPass, setShowLoginPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const openLogin = () => {
        setCards({ login: true, signup: false, resetPass: false, newPass: false})
    };

    const handleShowPass = () => {
        setShowLoginPass(!showLoginPass)
    }


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');

    const submitLogin = (e) => {
        e.preventDefault();

        setIsLoading(true)

        const sendData = {
            email: email,
            password: password,
            code: code
        }

        newPass(api, sendData, setPassword, setCode, setEmail, notifySuccess, notifyError, notifyInfo, setIsLoading, setCards);
    }

    
    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }


    return (

        <div className={`${cards.newPass ? 'newPassActive' : ''} login-card newPassCard `} id="">

            <div className="login-card-header">
                <span><IoPersonOutline className='icon-xl mb-1' /></span>
                <h1 data-en='Login' data-sr='Prijavljivanje'>Nova Lozinka</h1>
            </div>

            <form autoComplete="off" onSubmit={submitLogin}>

                <div className="form-imputs">
                    <div className="inputs">
                        <input autoFocus type="email" placeholder="Email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="inputs">
                        <input type={showLoginPass ? 'text' : 'password'} name="password" placeholder="Nova Lozinka" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <IoEyeOutline className='visible__pass' onClick={handleShowPass} />
                    </div>
                    <div className="inputs">
                        <input type="text" placeholder="Kod" name='code' value={code} onChange={(e) => setCode(e.target.value)} />
                    </div>
                </div>

                <div className="inputs">
                    <button className="btn" data-en='Login' data-sr='Prijava'>Sačuvaj novu</button>
                </div>
            </form>

            <div className="switch">
                <span onClick={openLogin} data-en='Go to Signup' data-sr='Idi na registraciju'>Nazad na prijavu</span>
            </div>
        </div>

    )
}


export default ResetCard