import React from 'react'
import './Devider.css';

const Devider = () => {
  return (

    <div className="devider"></div>

  )
}

export default Devider