// Home page - Showcase section data
import img1 from '../src/assets/img/gallery/d1.jpg'
import img2 from '../src/assets/img/gallery/d2.jpg'
import img3 from '../src/assets/img/gallery/d3.jpg'
import img4 from '../src/assets/img/gallery/d4.jpg'

import img5 from '../src/assets/img/gallery/r1.jpg'
import img6 from '../src/assets/img/gallery/r2.jpg'
import img7 from '../src/assets/img/gallery/r3.jpg'
import img8 from '../src/assets/img/gallery/r4.jpg'
import img9 from '../src/assets/img/gallery/r5.jpg'

import img12 from '../src/assets/img/gallery/co1.jpg'
import img13 from '../src/assets/img/gallery/co2.jpg'
import img14 from '../src/assets/img/gallery/co3.jpg'

import img15 from '../src/assets/img/gallery/r2-1.jpg'
import img16 from '../src/assets/img/gallery/r2-2.jpg'
import img17 from '../src/assets/img/gallery/r2-3.jpg'
import img18 from '../src/assets/img/gallery/r2-4.jpg'
import img19 from '../src/assets/img/gallery/r2-5.jpg'


// Custom
import custom_01 from '../src/assets/img/gallery/custom-01.jpg'
import custom_02 from '../src/assets/img/gallery/custom-02.jpg'
import custom_03 from '../src/assets/img/gallery/custom-03.jpg'
import custom_04 from '../src/assets/img/gallery/custom-04.jpg'
import custom_05 from '../src/assets/img/gallery/custom-05.jpg'
import custom_06 from '../src/assets/img/gallery/custom-06.jpg'
import custom_07 from '../src/assets/img/gallery/custom-07.jpg'
import custom_08 from '../src/assets/img/gallery/custom-08.jpg'


// Custom wedding
import custom_wed_01 from '../src/assets/img/gallery/custom-wed-01.jpg'
import custom_wed_02 from '../src/assets/img/gallery/custom-wed-02.jpg'
import custom_wed_03 from '../src/assets/img/gallery/custom-wed-03.jpg'



export const galleryEvents = [
    {
        id: 1,
        category: 'Predstava "Diplomac"',
    },
    {
        id: 2,
        category: 'Smilies revija na Fashion Selection',
    },
    {
        id: 4,
        category: 'Smilies revija na Fashion Selection',
    },
    {
        id: 5,
        category: 'Odeća po porudžbini kupaca',
    },
    {
        id: 6,
        category: 'Venčanice po porudžbini kupaca',
    },
    {
        id: 7,
        category: 'Kostimi',
    },
]


export const galleryImages = [
    {
        id: 1,
        category_id: 1,
        image: img1,
        imgDesc: 'Predstava "Diplomac"',
    },
    {
        id: 2,
        category_id: 1,
        image: img2,
        imgDesc: 'Predstava "Diplomac"',
    },
    {
        id: 3,
        category_id: 1,
        image: img3,
        imgDesc: 'Predstava "Diplomac"',
    },
    {
        id: 4,
        category_id: 1,
        image: img4,
        imgDesc: 'Predstava "Diplomac"',
    },
    {
        id: 8,
        category_id: 2,
        image: img5,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 9,
        category_id: 2,
        image: img6,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 10,
        category_id: 2,
        image: img7,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 11,
        category_id: 2,
        image: img8,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 12,
        category_id: 2,
        image: img9,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 15,
        category_id: 7,
        image: img12,
        imgDesc: 'kostimi',
    },
    {
        id: 16,
        category_id: 7,
        image: img13,
        imgDesc: 'kostimi',
    },
    {
        id: 17,
        category_id: 7,
        image: img14,
        imgDesc: 'kostimi',
    },
    {
        id: 21,
        category_id: 4,
        image: img15,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 22,
        category_id: 4,
        image: img16,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 23,
        category_id: 4,
        image: img17,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 24,
        category_id: 4,
        image: img18,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 25,
        category_id: 4,
        image: img19,
        imgDesc: 'Fashion Selection revija',
    },
    {
        id: 26,
        category_id: 5,
        image: custom_01,
        imgDesc: 'Smilies Odeca po meri Custom',
    },
    {
        id: 27,
        category_id: 5,
        image: custom_02,
        imgDesc: 'Smilies Odeca po meri Custom',
    },
    {
        id: 28,
        category_id: 5,
        image: custom_03,
        imgDesc: 'Smilies Odeca po meri Custom',
    },
    {
        id: 29,
        category_id: 5,
        image: custom_04,
        imgDesc: 'Smilies Odeca po meri Custom',
    },
    {
        id: 31,
        category_id: 5,
        image: custom_06,
        imgDesc: 'Smilies Odeca po meri Custom',
    },
    {
        id: 32,
        category_id: 5,
        image: custom_07,
        imgDesc: 'Smilies Odeca po meri Custom',
    },
    {
        id: 33,
        category_id: 5,
        image: custom_08,
        imgDesc: 'Smilies Odeca po meri Custom',
    },
    {
        id: 34,
        category_id: 6,
        image: custom_wed_01,
        imgDesc: 'Smilies Odeca po meri Custom',
    },
    {
        id: 35,
        category_id: 6,
        image: custom_wed_02,
        imgDesc: 'Smilies Odeca po meri Custom',
    },
    {
        id: 36,
        category_id: 6,
        image: custom_wed_03,
        imgDesc: 'Smilies Odeca po meri Custom',
    },

]

export default galleryImages