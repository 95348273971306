import React from 'react';
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import api from '../../api/api';
import resetPass from '../../utilities/resetPass';


// Styling

import { IoPersonOutline } from 'react-icons/io5'
import 'react-toastify/dist/ReactToastify.css';
import Message from '../Message/Message';
import LoaderSmall from '../Loader/LoaderSmall';


const RessetPassCard = ({ cards, setCards }) => {


    const [showLoginPass, setShowLoginPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const openSignup = () => {
      setCards({ login: true, signup: false, resetPass: false, newPass: false})
    };

    const handleShowPass = () => {
        setShowLoginPass(!showLoginPass)
    }


    const [email, setEmail] = useState('');

    const submitLogin = (e) => {
        e.preventDefault();

        setIsLoading(true)

        const sendData = {
            email: email,
        }

        resetPass(api, sendData, setEmail, notifySuccess, notifyError, notifyInfo, setIsLoading, setCards);
    }

    
    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }


    return (

        <div className={`${cards.resetPass ? 'resetPassActive' : ''} login-card resetCard `} id="">

            <div className="login-card-header">
                <span><IoPersonOutline className='icon-xl mb-1 mt-2' /></span>
                <h1 data-en='Login' data-sr='Prijavljivanje'>Reset Lozinke</h1>
            </div>

            <form autoComplete="off" onSubmit={submitLogin}>

                <div className="form-imputs mt-2">
                    <div className="inputs">
                        <input autoFocus type="email" placeholder="Email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>

                <div className="inputs">
                  { isLoading ? <button className="btn" > <LoaderSmall /> </button> :
                    <button className="btn" data-en='Login' data-sr='Prijava'>Reset</button> }
                </div>
            </form>

            <div className="switch">
                <span onClick={openSignup} data-en='Go to Signup' data-sr='Idi na registraciju'>Nazad na prijavu</span>
            </div>
        </div>
    )
}

export default RessetPassCard