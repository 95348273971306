import React from 'react'

const LoaderSmall = () => {
    return (
        <div className="loaderSmall-container">
            <div className="loaderSmall">
                <span></span>
            </div>
        </div>
    )
}

export default LoaderSmall