import React from 'react'
import { Link } from 'react-router-dom'

const ThankYouComponent = () => {


  return (
    <section className="container container-xxl">
        <div className="thankyou__content">

            <h2>Hvala na kupovini</h2>

            <p>Upravo ste izvršili kupovininu na <Link to={'/'}>smiliesonline.com</Link>.</p>

            <p>Na email koji ste uneli prilikom kupovine je poslat predračun sa podacima za plaćanje.</p>

            <p>Plaćanje možete izvršiti uplatom na račun ili IPS načinom plaćanja, skeniranjem QR koda sa predračuna.</p>


            <p className='back-to-home'><Link to={'/'}>Nazad na početnu stranicu.</Link></p>
        </div>
    </section>
  )
}

export default ThankYouComponent