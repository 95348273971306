import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './SimilarProducts.css'
import Button from '../Button/Button'
import Loader from '../Loader/Loader';


// Redux
import { useSelector, useDispatch } from 'react-redux';
import getRandomProducts from '../../utilities/getRadnomProducts';

// putanja slika
const slike = process.env.REACT_APP_BACKEND_PRODUCT_IMAGES;


const SimilarProducts = ({ proizvod }) => {

    const [randomData, setRandomData] = useState([]);

    const productsList = useSelector((state) => state.productsList)
    const { loading, data, message } = productsList;


    const { 
        podkategorija_podkat_id
    } = proizvod;


    
    useEffect(() => {


        const singleCat = data.filter(
            (cat) => podkategorija_podkat_id === cat.podkategorija_podkat_id
        );
        setRandomData(getRandomProducts(singleCat, 3));


        window.scrollTo(0, 0)
    }, [data])


    return (
        <section className='similar-items container-xxl'>
            <h2 data-en='Similar products' data-sr='Povezani porizvodi'>Povezani proizvodi</h2>

            <article className="similar-items-category">
                {
                    loading ? <Loader /> :

                    randomData.map((single) => {

                        // Niz sa slikama
                        let sveSlike = single.slika_ime;
                        let splitSlike = sveSlike.split(',');

                            return (
                                <div key={single.proizvod_id} className="similar-items-category-cont" >
                                    <h3 >{single.proizvod_naziv_sr}</h3>
                                    <img src={slike + splitSlike[0]} alt={single.proizvod_naziv_sr} />
                
                                    <Link className="btn" to={`/product/${single.proizvod_id}`}> Pogledaj </Link>
                                </div>
                            )
                        })
                }
            </article>
        </section>
    )
}

export default SimilarProducts