import React, { useState } from 'react'
import { RiSunLine, RiMoonLine, RiLineChartLine,  RiShoppingCartLine, RiApps2Line, RiDashboardLine, RiSettings5Line} from 'react-icons/ri'
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { getDashOrders } from "../../../redux/features/orders/ordersSlice"



const Navbar = ({ asideMenu, setAsideMenu }) => {

    const [devider, setDevider] = useState(false);

    const ordersList = useSelector((state) => state.ordersList)
    const { ordersLoading, ordersData, ordersMessage } = ordersList;

  

    return (
        <aside className={`${asideMenu ? 'closeAside' : ''} aside__menu`}>

            <Link to="/" >
                <div className="aside__menu-top" >
                    <div className="aside__menu-top-left"></div>
                </div>
            </Link>

            <div className="aside__menu-bottom" id="asideBottom">

                <div className="aside__menu-bottom-content group-hover">
                    <div className="group">
                        <div className='group-header'>
                            <Link to="/orders" className='link-style-icon'><RiShoppingCartLine className="icon-main icon-dash-green ml-18 " /></Link>
                            <span className='link-style'><Link to="/orders" className='link-style'>Porudzbine</Link></span>
                            {
                                ordersData.greska ? (
                                    <span className='order__product-count '><h3>0</h3></span>
                                ) : (
                                    <span className='order__product-count '><h3>{ordersData.length}</h3></span>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className={`${devider ? 'closedDevider' : ''} devider__aside`}></div>

                {/* <div className="aside__menu-bottom-content group-hover">
                    <div className="group">
                        <div className='group-header'>
                            <Link to="/dashboard" className='link-style-icon'><RiDashboardLine className="icon-main ml-18" /></Link>
                            <span className='link-style'><Link to="/dashboard" className='link-style'>Dashboard</Link></span>
                        </div>
                    </div>
                </div>

                <div className="aside__menu-bottom-content group-hover">
                    <div className="group">
                        <div className='group-header'>
                            <Link to="/userinfo" className='link-style-icon'><RiSettings5Line className="icon-main ml-18" /></Link>
                            <span className='link-style'><Link to="/userinfo" className='link-style'>UserInfo</Link></span>
                        </div>
                    </div>
                </div> */}

            </div>


            {/* <div className="theme__switch" >
                <RiSunLine className="icon-main" />
                <div className="switcher" >
                    <span className="switcher__slider slider-move "></span>
                </div>
                <RiMoonLine className="icon-main" />
            </div> */}
        </aside>
    )
}

export default Navbar