import { configureStore } from '@reduxjs/toolkit';


import categorySliceReducer from './redux/features/categories/categorySlice';
import subCategorySliceReducer from './redux/features/subcategories/subCategorySlice';
import cartSlice from './redux/features/cart/cartSlice';
import productsSlice from './redux/features/products/productsSlice';
import productsDashSlice from './redux/features/products/productsDashSlice';
import ordersSlice from './redux/features/orders/ordersSlice';


export const store = configureStore({
    reducer: {
        categoryList: categorySliceReducer,
        subCategoryList: subCategorySliceReducer,

        productsList: productsSlice,
        productsListDash: productsDashSlice,

        cartList: cartSlice,
        ordersList: ordersSlice,
    }
})

export default store;