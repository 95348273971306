import React, { useState, useEffect } from 'react'

import { galleryImages } from '../../galleryData';
import { galleryEvents } from '../../galleryData';

import Modal from '../Modal/Modal';

const slike = process.env.REACT_APP_BACKEND_PRODUCT_IMAGES;



const Gallery = () => {


// const [controller, setController] = useState({toggler: false, slide: 1});
// const [controller, setController] = useState(false);
// const [imageArr, setImageArr] = useState([]);
const [sourceIndex, setsourceIndex] = useState(0);

const [modal, setModal] = useState(false);
const [modalId, setModalId] = useState('');

const openModal = () => {
    setModal(!modal);
}

const [img, setImg] = useState(null);

// useEffect(() => {
//     setImageArr(galleryImages)
// }, [])


const openGallery = (image) => {
    // setController({
    //     toggler: true,
    //     slide: id
    // });


    // setController(!controller);
    // setImg(image)

    

    // console.log(id)
    // setsourceIndex(id);+
  }


//   const galleryShow = (id, cat) => {

//     // setImg(i)
//     imageArr.forEach(element => {

//         if(element.category_id == cat){
//             return(

//                 <FsLightbox 
//                     exitFullscreenOnClose={true}
//                     loadOnlyCurrentSource={true}
//                     toggler={controller}
//                     type="image"
//                     // customAttributes={[
//                     //     {alt: desc}
//                     // ]}
            
//                     sources={element[0]}
//                     // slide={controller.slide}
                
//                     // key={id}
//                 />
//             )
//         }
    
//     });

 
//   }


  return (
    <div className="container container-xxl">
        <div className="gallery__container">
            
            <h2>Neki od naših radova</h2>

            {
                galleryEvents.map((cat) => {
                    return( 
                        <div className="category" key={cat.id}>
                            <h3>{cat.category}</h3>

                            <div className="category-item">  
                                <div className="gallery">
                                    {
                                        galleryImages.map((img, idx) => {

                                            if(cat.id === img.category_id){

                                                return ( 
                                                    <>
                                                        <img  key={img.id} src={img.image} alt={img.imgDesc} 
                                                        onClick={() => [
                                                            setImg(img.image) ,
                                                            openModal(),
                                                            setModalId('image')
                                                        ]}
                                                        
                                                        />
                                                        {/* <img  key={img.id} src={img.image} alt={img.imgDesc} onClick={() => setController(!controller)}/> */}
                                                        {/* {galleryShow(img.id, img.category_id)} */}

                                                       
                                                    </>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>

                        </div>
                    )
                })
            }
            {/* <img style={{height: 20 + 'rem' }} src={img}/> */}
            <Modal img={img} modal={modal} setModal={setModal} modalId={modalId}/>
        </div>
    </div>
  )
}

export default Gallery