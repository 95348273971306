import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ContactOptions from '../../components/ContactOptions/ContactOptions';
import { motion } from 'framer-motion/dist/framer-motion'

import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import Devider from '../../components/Devider/Devider';

import { Helmet } from 'react-helmet-async';

const Contact = () => {

    // let curentLocation = useLocation();

    // useEffect(() => {
    //     setLocation(curentLocation.pathname);
    // }, []);


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Kontaktirajte nas - Maršala Birjuzova 45 | SmiliesOnline Shop</title>

                <meta name="description" content="Kontaktirajte SmiliesOnline prodaju putem naše kontakt forme. Takođe možete pozvati naš broj telefona za pomoć i podršku." ></meta>
 
                <link  rel='canonical' href='/Contact'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />

            <ContactOptions />
        </motion.div>
    )
}

export default Contact