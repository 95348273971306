import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'

//Styling
import './Modal.css';
import { IoCloseOutline } from 'react-icons/io5';
import ContactForm from '../ContactForm/ContactForm';
import ContactPhone from '../ContactPhone/ContactPhone';

const Modal = ({ modal, setModal, modalId, setDetailsWindow, detailsWindow, img }) => {

    const closeModalDiv = (e) => {
        if(e.target.classList.contains('modalActive')){
            setModal(!modal);
        }
    }


    const closeModal = () => {
        setModal(!modal);
    }


    const closeModalDash = () => {
        setModal(false);
        setDetailsWindow(!detailsWindow)
    }


    switch (modalId) {
        case 'mail':
            return (
                <div className={`${modal ? 'modalActive' : ''} modal`} onClick={closeModalDiv}>
                    <span><IoCloseOutline className='icon-xl' onClick={closeModal} /></span>

                    <ContactForm setModal={setModal} modalId={modalId}/>
                </div>
            )
        case 'phone':
            return (
                <div className={`${modal ? 'modalActive' : ''} modal`} onClick={closeModalDiv} >
                    <span><IoCloseOutline className='icon-xl' onClick={closeModal} /></span>

                    <ContactPhone />
                </div>
            )
        case 'orderDetails':
            return (
                <div className={`${modal ? 'modalActive' : ''} modal`} onClick={closeModalDash}></div>
            )
        case 'image':
            return (
                <motion.div className={`${modal ? 'modalActive' : ''} modal`} 
                    onClick={closeModal}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    <span><IoCloseOutline className='icon-xl' onClick={closeModalDiv} /></span>
                    <img style={{height: 80 + '%' }} src={img}/>
                </motion.div>
            )

    }
}

export default Modal