import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion'

import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import Devider from '../../components/Devider/Devider';
import Footer from '../../components/Footer/Footer';
import PaymentForm from '../../components/PaymentForm/PaymentForm';

import { Helmet } from 'react-helmet-async';

const Payment = ({ location, setLocation }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Poručivanje | SmiliesOnline Shop</title>

                <meta name="description" content="SmiliesOnline Shop stranica za poručivanje odabranih proizvoda." ></meta>

                <link  rel='canonical' href='/Payment'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />
            <div className='container'>

                <PaymentForm />

            </div>
            <Footer />

        </motion.div>
    )
}

export default Payment