import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion'

import Footer from '../../components/Footer/Footer';

import ProductCard from '../../components/ProductCard/ProductCard';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import Devider from '../../components/Devider/Devider';

import { Helmet } from 'react-helmet-async';


const Products = ({ location, setLocation }) => {

    let curentLocation = useLocation();

    useEffect(() => {
        // setLocation(curentLocation.pathname);

        window.scrollTo(0, 0)
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Proizvodi | SmiliesOnline Shop</title>

                <meta name="description" content="SmiliesOnline Shop stranica koja prkazuje naše proizvode iz odabrane kategorije." ></meta>

                <link  rel='canonical' href='/Products'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />

            <ProductCard />
            <Footer />

        </motion.div>

    )
}

export default Products