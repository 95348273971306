import React from 'react';
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import Button from '../Button/Button'
import api from '../../api/api';
import jwt from 'jwt-decode'

import login from '../../utilities/login';


// Styling
import { IoPersonOutline, IoPersonAddOutline, IoEyeOutline } from 'react-icons/io5'
import 'react-toastify/dist/ReactToastify.css';
import Message from '../Message/Message';
import LoaderSmall from '../Loader/LoaderSmall';


const LoginCard = ({ cards, setCards }) => {

    const [showLoginPass, setShowLoginPass] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleShowPass = () => {
        setShowLoginPass(!showLoginPass)
    }


    const submitLogin = (e) => {
        e.preventDefault();

        setIsLoading(true)

        const sendData = {
            email: email,
            password: password,
        }

        login(api, sendData, setEmail, setPassword, notifyError, notifySuccess, notifyInfo, setCards, navigate, jwt, setIsLoading)
    }


    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }


    const openRegistration = () => {
        setCards({ login: false, signup: true, resetPass: false, newPass: false})
    };

    const openResetPass = () => {
        setCards({ login: false, signup: false, resetPass: true, newPass: false})
    }


    return (

        <div className={`${cards.login ? '' : 'loginActive'} login-card loginCard `} id="">

            <div className="login-card-header">
                <span><IoPersonOutline className='icon-xl mb-1' /></span>
                <h1 data-en='Login' data-sr='Prijavljivanje'>Prijavljivanje</h1>
            </div>

            <form onSubmit={submitLogin}>

                <div className="form-imputs">
                    <div className="inputs">
                        <input  type="email" autoFocus placeholder="Email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="inputs">
                        <input type={showLoginPass ? 'text' : 'password'} name="password" placeholder="Lozinka" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <IoEyeOutline className='visible__pass' onClick={handleShowPass} />
                    </div>

                    <div className="pass__reset">
                        <span onClick={openResetPass} data-en='Forgotten password?' data-sr='Zaboravili ste lozinku?'>Zaboravili ste lozinku?</span>
                    </div>
                </div>

                <div className="inputs">
                    { isLoading ? <button className="btn" > <LoaderSmall /> </button> :
                        <button className="btn" data-en='Login' data-sr='Prijava'>Prijava</button> }
                </div>
            </form>

            <div className="switch">
                <span onClick={openRegistration} data-en='Go to Signup' data-sr='Idi na registraciju' >Idi na registraciju</span>
            </div>
        </div>

    )
}

export default LoginCard 