import React, {  useEffect } from 'react'
import { RiCloseLine } from 'react-icons/ri'

import { useSelector, useDispatch } from 'react-redux';
import { getDashOrders, editOrder } from "../../../redux/features/orders/ordersSlice"


import OrderDetailsView from '../OrderDetailsView/OrderDetailsView';


const DetailsWindow = ({ detailsWindow, setDetailsWindow, orderDetailsId, orderOption, setOrderOption, productDetailsId, productOption, setProductOption, userOption, setUserOption, modal, setModal, modalId, setModalId }) => {

    const ordersList = useSelector((state) => state.ordersList)
    const { ordersLoading, ordersData, ordersMessage, dataOrderUpdate } = ordersList;
    const { order } = dataOrderUpdate;



    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDashOrders());
    }, [dispatch]);


    const closeDetailsWindow = () => {
        setDetailsWindow(!detailsWindow);
        setModal(!modal);
    }

    return (
        <section className={`${detailsWindow ? 'detailsActive' : ''} details__window`} >
                <RiCloseLine className='icon-main icon-position' onClick={() => closeDetailsWindow()} />

                {/*Switch WindowDetail za opcije naloga*/}
                <article>
                    {
                        order ? (
                            ordersData.map((ord, idx) => {

                                if (order.por_id === ord.por_id && orderOption == 'viewOrder') {

                                    return (
                                        <OrderDetailsView key={order.por_id}
                                            setDetailsWindow={setDetailsWindow}
                                            detailsWindow={detailsWindow}
                                            setOrderOption={setOrderOption}
                                            modal={modal}
                                            setModal={setModal}
                                        />
                                    )
                                }
                            })

                        ) : (
                            <h3 className='color-danger mt-1 tc-danger-muted'>{ordersData.greska}</h3>
                        )
                    }
                </article>
        </section>
    )
}

export default DetailsWindow