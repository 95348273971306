const contactUs = (sendData, api, setModal, setIsLoading, notifyError, notifyInfo, notifySuccess, setName, setEmail, setTitle, setMessage) => {


    api({
        method: 'post',
        url: 'contactus.php',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {

                notifySuccess(response.data.uspesno);
                setEmail('');
                setName('');
                setTitle('');
                setMessage('');
                setModal('');

                setIsLoading(false)

            } else if (response.data.greska) {

                notifyError(response.data.greska);
                setIsLoading(false)
            } else if (response.data.info) {

                notifyInfo(response.data.info);
                setIsLoading(false)
            }
        })
}

export default contactUs