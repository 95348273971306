import React, { useState, useEffect } from 'react'
import { useNavigate} from 'react-router-dom';

import Header from '../../components/Dashboard/Header/Header'
import Navbar from '../../components/Dashboard/Navbar/Navbar'
import ErrorPage from '../ErrorPage/ErrorPage';
// import jwt from 'jwt-decode'

import { useDispatch } from 'react-redux';
import { getDashOrders } from "../../redux/features/orders/ordersSlice"
import MobileNavbar from '../../components/Dashboard/MobileNavbar/MobileNavbar';


const Dashboard = () => {

    document.title = 'User Dash';
    const navigate = useNavigate();

    // Session
    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");

    const [asideMenu, setAsideMenu] = useState(false);
    const [devider, setDevider] = useState(false);

    const dispatch = useDispatch();


    useEffect(() => {
        const asideMenuStorage = localStorage.getItem('sidebar');
        if (asideMenuStorage === 'closed') {
            setAsideMenu(true);
        }

        setTimeout(() => {
            if (smiliesSession) {
                sessionStorage.removeItem('SmiliesOnlineLog')
                navigate('/')
            }
        }, 900000);

        dispatch(getDashOrders());
    }, [asideMenu, dispatch]);



    if (smiliesSession) {
        // const token = jwt(smiliesSession);

            return (
                <div className='dashboard-container'>
                    <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
                    <div className="dashboard__main">
                        <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} />

                        <div className="main__section">
                            {/*Ovde idu komponente svake stranice. iznad divovi ostaju isti na svakoj stranici*/}


                            <MobileNavbar />    
                        </div>
                    </div>
                </div>
            )
    } else {
        return (
            <ErrorPage nologin />
        )
    }

}

export default Dashboard