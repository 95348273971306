import React, { useEffect } from 'react'
import { motion } from 'framer-motion/dist/framer-motion'

import Footer from '../../../components/Footer/Footer'
import Navbar from '../../../components/Navbar/Navbar';
import Header from '../../../components/Header/Header';
import Devider from '../../../components/Devider/Devider';

import { Helmet } from 'react-helmet-async';


const Delivery = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Politika iporuke | SmiliesOnline Shop</title>

                <meta name="description" content="SmiliesOnline Shop stranica na kojoj možete da se upoznate sa našom politikom isporuke porudžbina." ></meta>

                <link  rel='canonical' href='/Delivery'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />

            <section className="legal__container">
                <div className="legal__container-content container-xxl">
                    <h2>Uslovi isporuke</h2>
                    <p>Prodavac se obavezuje da kupljenu robu isporuči Potrošaču slanjem robe preko kurirske službe, sa kojom ima zaključen Ugovor o pružanju ekspresnih usluga u unutrašnjem saobraćaju. </p>
                    <p>Za sve porudžbine vrednosti manje od 8000,00 dinara Potrošač se, prihvatajući ove Uslove korišćenja, obavezuje da na ime troškova isporuke prilikom preuzimanja pošiljke plati, direktno kuriru, iznos od 390,00 din sa PDV-om. Za sve porudžbine čija je vrednost 8000,00 dinara ili više isporuka je besplatna.</p>
                    <p>Nakon prijema porudžbine od strane Potrošača, Služba Internet prodaje kompanije PR Smilies vrši proveru dostupnosti i ispravnosti artikala. Ukoliko su poručeni artikli dostupni Potrošaču se šalje potvrda o prijemu porudžbine i počinju da teku rokovi za isporuku. Ukoliko poručenog artikla nema na stanju, Potrošač dobija mejl o nedostupnosti artikla.</p>
                    <p>O vremenu dostave Potrošač će biti obavešten telefonom od strane kurirske službe.</p>
                    <p>Nakon preuzimanja paketa od strane kurirske službe, Potrošaču će biti prosleđena automatska poruka sms porukom, e-mail, whatsup ili viber aplikacijom (u zavisnosti od kurirske službe) sa kodom pošiljke koju može pratiti na internet stranici kurirke službe.</p>
                    <p>Rok isporuke iznosi 2-3 radna dana od prvog narednog dana od dana izvršenja porudžbine od strane potrošača.</p>
                    <p>Isporuke paketa se vrše samo radnim danima, tj. vikendom se isporuke paketa ne vrše.</p>
                    <p>Molimo Vas za razumevanje za moguća kašnjenja u isporuci u periodima akcija i sezonskih sniženja obzirom da isporuka zavisi od eksternih službi. </p>
                    <p>Takođe, napominjemo da period obrade porudžbine zavisi od trenutka kreiranja porudžbine. Prudžbine kreirane do 12 časova biće u obradi istog radnog dana, a porudžbine kreirane nakon 12 časova biće u obradi sledećeg radnog dana.</p>
                </div>
            </section>

            <Footer />
        </motion.div>
    )
}

export default Delivery