// Custom wedding
import wed_pano_01 from '../src/assets/img/splide/wed_pano_01.jpg'
import wed_pano_02 from '../src/assets/img/splide/wed_pano_03-min.JPG';

// Every day colection
import ever_pano_01 from '../src/assets/img/splide/ever_pano_01-min.JPG'
import ever_pano_02 from '../src/assets/img/splide/ever_pano_02-min.JPG'
import ever_pano_03 from '../src/assets/img/splide/ever_pano_03-min.JPG'
import ever_pano_04 from '../src/assets/img/splide/ever_pano_05-min.JPG'

// Business
import bus_pano_03 from '../src/assets/img/splide/C1-1600x1100.jpg'

export const splideImages = [

    {
        id: 1,
        image: wed_pano_01,
        desc: 'Smilies wedding dress collection'
    },
    {
        id: 3,
        image: bus_pano_03,
        desc: 'Smilies business collection'
    },
    {
        id: 2,
        image: wed_pano_02,
        desc: 'Smilies wedding dress collection'
    },
    // {
    //     id: 4,
    //     image: ever_pano_01,
    //     desc: 'Smilies everyday collection'
    // },
    {
        id: 5,
        image: ever_pano_02,
        desc: 'Smilies everyday collection'
    },
    {
        id: 6,
        image: ever_pano_03,
        desc: 'Smilies everyday collection'
    },
    {
        id: 7,
        image: ever_pano_04,
        desc: 'Smilies everyday collection'
    },

]

export default splideImages