import React from 'react'

const AboutComp = () => {
  return (
    <section className="container container-xxl">
        <div className="about__content">

          <h2>O nama</h2>

            <p>Modni atelje Smilies počeo je sa radom 1999. godine, u Boru, kada su hobi i pasija uobličeni u profesiju. <br/>
                Cilj nije bio pretenciozno stvaranje mode kao takve, već jedna igra i predivno putovanje kroz divan svet krpica, boja i materijala.
                Naša zamisao je da napravimo komade koji su drugačiji, a opet inspirisani klasikom. <br/> Naš fokus je na pružanje kvalitetnih 
i               personalizovanih usluga našim klijentima.
                Danas tim Smilies sanja i pretvara u stvarnost iste modne snove u jedninstven izraz. Želja nam je da Vaše snove pretvorimo u stvarnost.
                Vi imate ideju? Naš tim nudi realizaciju kroz divne odevne predmete koji su po vašoj želji, stvoreni sa puno ljubavi od najfinijih i najkvalitetnijih materijala.</p>

            {/* <h3>Komponenta2: Componenta sa galerijom sa radovima i revijama.</h3> */}
        </div>
    </section>
  )
}

export default AboutComp