import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwt from 'jwt-decode'

import api from '../../../api/api'
import authsession from "../../../utilities/authsession";

export const getDashOrders = createAsyncThunk("DASH_ORDERS/GET_ALL_ORDERS", async () => {

    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");
    const user = jwt(smiliesSession);

    try {
        const { data } = await api({
            method: 'get',
            headers: { 'Authorization': authsession() },
            url: `orderc.php?fun=front&id=${user.data.id}`,
        });
        return data;
    } catch (error) {
        return error.message;
    }
});

const initialState = {
    ordersData: [],
    ordersLoading: false,
    ordersMessage: '',

    dataOrderUpdate: [],
}

export const ordersDashSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        editOrder(state, action) {
            state.dataOrderUpdate = action.payload;
        },
    },
    extraReducers: {

        [getDashOrders.pending]: (state, action) => {
            state.ordersLoading = true;
        },
        [getDashOrders.fulfilled]: (state, action) => {
            state.ordersLoading = false;
            state.ordersData = action.payload;
        },
        [getDashOrders.rejected]: (state, action) => {
            state.ordersLoading = false;
            state.ordersMessage = action.payload;
        },
    },
});

export const { editOrder } = ordersDashSlice.actions
export default ordersDashSlice.reducer;