import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion'

import Footer from '../../../components/Footer/Footer'
import Navbar from '../../../components/Navbar/Navbar';
import Header from '../../../components/Header/Header';
import Devider from '../../../components/Devider/Devider';

import { Helmet } from 'react-helmet-async';


const Terms = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Uslovi korišćenja | SmiliesOnline Shop</title>

                <meta name="description" content="SmiliesOnline Shop stranica na kojoj možete da se upoznate sa uslovima korišćenja SmilesOnline prodavnice." ></meta>

                <link  rel='canonical' href='/Terms'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />

            <section className="legal__container">
                <div className="legal__container-content container-xxl">
                    <h2>Uslovi korišćenja</h2>
                    <p>Opšti uslovi poslovanja SmiliesOnline internet prodavnice detaljnije su utvrđeni po oblastima, kojima možete pristupiti klikom na željenu oblast, a sve u skladu sa važećim Zakonom o zaštiti potrošača i međunarodnim kodeksom elektronskog poslovanja. Opšti uslovi poslovanja definišu delatnost Internet prodavnice, korisnike prava i poslovni odnos između prodavca i potrošača.</p>
                    <p>Internet prodavnica je informacioni sistem namenjen predstavljanju i prodaji proizvoda potrošačima. Njime upravlja Pr Smilies, Beograd - Stari grad. Korisnik je osoba koja koristi navedeni sistem (u daljem tekstu: “Potrošač” u Internet prodavnici).</p>
                    <p>SmilieOnline internet prodavnica omogućava Potrošačima korišćenje usluga i sadržaja svoje stranice, a prava i obaveze Prodavca i Potrošača su regulisana dole navedenim Uslovima korišćenja. Uslovi korišćenja se primenjuju na sve sadržaje i usluge stranice www.smiliesonline.com . Korišćenjem bilo kojeg dela stranice, smatra se da su Potrošači upoznati Uslovima korišćenja SmiliesOnline internet prodavnice, kao i da prihvataju korišćenje sadržaja ove stranice isključivo za ličnu upotrebu i na vlastitu odgovornost.</p>

                    <h3>Registracija</h3>
                    <p>Potrošač prihvata propozicije ovog sajta samim činom registracije. Proces registracije je izuzetno jednostavan i od Potrošača zahteva samo osnovne podatke. Prilikom registracije Potrošač popunjava formular, a svaki naredni put je dovoljno da se Potrošač samo uloguje na svoj nalog. Kao registrovani član Potrošač ima mogućnost da u svako doba podnese zahtev da se iščlanjenje, čime će i Vaši podaci biti izbrisani iz naše baze podataka, u roku od 7 do 10 dana od dana kada zahtev bude primljen.</p>

                    <h3>Poručivanje</h3>
                    <p>Sve informacije o procesu kupovine možete pogledati na KAKO KUPITI.</p>

                    <h3>Način plaćanja</h3>
                    <p>Potrošač robu može platiti pouzećem ili uplatnom na žiro račun.</p>

                    <p>Radimo na tome da u skorijem vremenskom periodu obezbedimo plaćanje platnim karticana sa naše internet stranice, do tada, poručenu robu možete platiti korišćenjem mobilne aplikacije vaše banke sa podacima koje će vam biti dostavljeni</p>

                    <p>Plaćanje gotovinom pri isporuci pošiljke: </p>
                    <p>Opcija plaćanja pouzećem omogućuje Potrošaču da plati vrednost porudžbine prilikom dostave poručene robe. Plaćanje pouzećem se vrši isključivo u gotovini, kuriru prilikom same isporuke na navedenu adresu.</p>

                    <p>Sva plaćanja se biti obavljena u dinarima (RSD).</p>

                    <h3>Proces poručivanja</h3>
                    <p></p>

                    <h3>Cene u PR SMilies internet prodavnici</h3>
                    <p>Sve cene su iskazane u dinarima sa uključenim PDV-om. Prodavac zadržava pravo izmene cena bez prethodnog obaveštavanja kupaca, ako nije drugačije navedeno (u slučaju akcija i specijalnih popusta). Cene važe u trenutku kreiranja porudžbine i imaju dejstvo i validnost i za naredni period. Navedene cene važe samo za kupovinu u internet prodavnici i mogu da se razlikuju od cena u maloprodajnim objektima Prodavca.</p>

                    <h3>Uslovi i način isporuke</h3>
                    <p>Za više informacija o uslovima i načinu isporuke kliknite <Link to='/Delivery'>ovde.</Link></p>

                    <h3>Politika privatnosti</h3>
                    <p>Više informacija o Politici privatnosti možete pročitati <Link to='/Privacy'>ovde.</Link> </p>

                    <h3>Politika kolačića</h3>
                    <p>Više informacija o Politici kolačića možete pročitati <Link to='/Cookies'>ovde.</Link> </p>

                    <h3>Završne odredbe</h3>
                    <p>Isporučilac zadržava pravo da može menjati ove uslove bez prethodnog obaveštavanja svojih potrošača. Svaki potrošač prilikom naručivanja proizvoda može proveriti trenutno važeće uslove.</p>
                </div>
            </section>

            <Footer />
        </motion.div>
    )
}

export default Terms