import React, { useState } from 'react'
import api from '../../api/api';

import { toast } from 'react-toastify';
import Message from '../Message/Message';

//Style
import './ContactForm.css'
import { IoMailOutline } from 'react-icons/io5'
import contactUs from '../../utilities/contactUs';
import LoaderSmall from '../Loader/LoaderSmall';


const ContactForm = ({ setModal, modalId }) => {

    const [name, setName ] = useState('');
    const [email, setEmail ] = useState('');
    const [title, setTitle ] = useState('');
    const [message, setMessage ] = useState('');
    const [isLoading, setIsLoading ] = useState(false);


    // Message je stilizovana komponenta Unutar Toast-a
    const notifyError = (odgovor) => {
        toast.error(<Message error={odgovor} />)
    }
    const notifySuccess = (odgovor) => {
        toast.success(<Message success={odgovor} />);
    }
    const notifyInfo = (odgovor) => {
        toast.info(<Message info={odgovor} />);
    }


    const sendMail = (e) => {

        e.preventDefault();
        setIsLoading(true);

        const sendData = {
            name: name,
            email: email,
            title: title,
            message: message
        }

        contactUs(sendData, api, setModal, setIsLoading, notifyError, notifyInfo, notifySuccess, setName, setEmail, setTitle, setMessage);
    }

    
    return (
        <div className="modal--content">
            <div className="modal--content-header">
                <IoMailOutline className='icon-xl' />
                <h1>Unesite svoje podatke</h1>
            </div>

            <form id="myForm" autoComplete="off" onSubmit={sendMail}>
                <div className="inputs">
                    <input autoFocus type="text" name="name" id="name" placeholder="ime i prezime" value={name} onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className="inputs">
                    <input type="email" name="email" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className="inputs">
                    <input type="text" name="title" id="title" placeholder="Naslov email-a" value={title} onChange={(e) => setTitle(e.target.value)}/>
                </div>
                <div className="inputs">
                    <textarea id="message" cols="30" rows="6" name="message" placeholder="Poruka" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                </div>

                <div className="inputs">
                    <p>This site is protected by reCAPTCHA, the Google Privacy Policy <br /> and Terms of Service apply.</p>
                </div>

                    {
                        isLoading ? 
                
                            (<div className="inputs ">
                                <button className="btn btn-with-100"> <LoaderSmall /> </button> 
                            </div>):
                            (<div className="inputs ">
                               <button className="btn btn-with-100">Pošalji</button> 
                            </div>)   
                    }
            </form>
        </div>
    )
}

export default ContactForm