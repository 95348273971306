import React from 'react';

import { motion } from 'framer-motion/dist/framer-motion'

import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import Devider from '../../components/Devider/Devider';
import Footer from '../../components/Footer/Footer';

import { Helmet } from 'react-helmet-async';
import ThankYouComponent from '../../components/ThanYouComponent/ThankYouComponent';

const ThankYou = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}>

      <Helmet >
          <title>Hvala na kupovini</title>

          <meta name="description" content="Stranica zahvalnice na kupovini."/>
          
          <link  rel='canonical' href='/Thankyou'/>             
      </Helmet>
      

      <Navbar />
      <Header />
      <Devider />

      <ThankYouComponent />

      <Footer />
    </motion.div>
  )
}

export default ThankYou