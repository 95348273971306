const login = ( api, sendData, setEmail, setPassword, notifyError, notifySuccess, notifyInfo, setCards, navigate, jwt, setIsLoading ) => {


    api({
        method: 'post',
        url: 'login.php',
        data: sendData,
    })
        .then((response) => {

            if (response.data.uspesno) {

                setEmail('');
                setPassword('');

                const odgovor = response.data;
                if (odgovor) {

                    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");

                    if (smiliesSession) {
                        notifyInfo("Već ste ulogovani");
                        navigate('/')
                        setIsLoading(false)
                    } 
                    else if (!smiliesSession) {

                        sessionStorage.setItem("SmiliesOnlineLog", JSON.stringify({
                            // login:true,
                            token:response.data.token
                        }));

                        const token = jwt(response.data.token);

                        if (token.data.status == 'Korisnik') {

                            navigate('/')
                            notifySuccess(response.data.uspesno);
                            setIsLoading(false)
                        } else {

                            notifySuccess("Greška prilikom logovanja");
                        }
                    }

                } else {
                    notifyError("Login error.");
                    setIsLoading(false)
                }

            } else if (response.data.greska) {
                notifyError(response.data.greska);
                setIsLoading(false)

            } else if (response.data.info) {
                notifyInfo(response.data.info);
                setIsLoading(false)
            }
        })
}

export default login