import React, { useEffect } from 'react'
import { motion } from 'framer-motion/dist/framer-motion'

import Footer from '../../../components/Footer/Footer'
import Navbar from '../../../components/Navbar/Navbar';
import Header from '../../../components/Header/Header';
import Devider from '../../../components/Devider/Devider';

import { Helmet } from 'react-helmet-async';


const Refund = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Helmet >
                <title>Uslovi povraćaja sredstava | SmiliesOnline Shop</title>

                <meta name="description" content="SmiliesOnline Shop stranica na kojoj možete da se upoznate sa uslovima povraćaja sredstava." ></meta>

                <link  rel='canonical' href='/Refund'/>             
            </Helmet>


            <Navbar />
            <Header />
            <Devider />

            <section className="legal__container">
                <div className="legal__container-content container-xxl">

                    <p>Refund</p>

                </div>
            </section>

            <Footer />
        </motion.div>
    )
}

export default Refund