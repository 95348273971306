import React, { useState, useEffect } from 'react'
import Header from '../../components/Dashboard/Header/Header'
import Navbar from '../../components/Dashboard/Navbar/Navbar'

import jwt from 'jwt-decode'

import UserInfoComp from '../../components/Dashboard/UserInfo/UserInfoComp'
import ErrorPage from '../ErrorPage/ErrorPage'
import MobileMenu from '../../components/MobileMenu/MobileMenu'

const UserInfo = () => {

    // Session
    const smiliesSession = sessionStorage.getItem("SmiliesOnlineLog");

    const [asideMenu, setAsideMenu] = useState(false);

    if (smiliesSession) {

      return (
    
        <div className='dashboard-container'>
          <Navbar asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
          <div className="dashboard__main">
              <Header asideMenu={asideMenu} setAsideMenu={setAsideMenu} />
    
              <div className="main__section">
                <UserInfo />
    
                <MobileMenu />
              </div>
          </div>
        </div>
      )
    } else {
        return (
            <ErrorPage nologin />
        )
    }
}

export default UserInfo